import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';

import * as Yup from 'yup';

import { users } from '@actions/account';

const MemberList = () => {
  const dispatch = useDispatch();

  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [pageLimit, setPageLimit] = useState(20);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  
  const [searchText, setSearchText] = useState('');
  const [searchReset, setSearchReset] = useState(false);

  useEffect(() => {
    getList();
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if(searchReset){
      handleSearch()
    }
  }, [searchReset]);

  const getList = () => {
    if(searchReset){
      setSearchReset(false);
    }

    dispatch(users({
      page: currentPage,
      limit: pageLimit,
      text: searchText
    }))
    .then((res) => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
      setCheckList([]);
    })
  }

  const handleSearch = () => {
    setCurrentPage(1);
    getList();
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };


  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }


  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(value);
    setCurrentPage(1);
  }
  
  return (
    <>
      {currentItems && <div className="container_adm">
        <div className="adm_title_wrap mb30">
            <div className="adm_title__left">
                <div className="adm_title mr20">회원관리</div>
                <span className="box_green">정상회원 {totalItemCount - _.filter(currentItems, r=>r.leaved).length}명</span>
                <span className="box_green_line">탈퇴회원 {_.filter(currentItems, r=>r.leaved).length}명</span>
            </div>
            <div className="adm_title__right">
                <select name="member_filter" id="member_filter">
                    <option value="member_id">아이디</option>
                    <option value="member_name">이름</option>
                    <option value="member_nick">닉네임</option>
                    <option value="member_mobile">휴대폰번호</option>
                </select>
                <div className="search_wrap">
                    <label htmlFor="검색어 입력" className="hide">검색어 입력</label>
                    <input type="text" id="search_text" placeholder="검색어를 입력해 주세요"/>
                    <button type="button" className="btn_search"><span className="hide">검색</span></button>
                </div>
                <select name="table_row" id="table_row" onChange={onChangePageLimit}>
                    <option value="10" selected={pageLimit === 10}>10</option>
                    <option value="20" selected={pageLimit === 20}>20</option>
                    <option value="30" selected={pageLimit === 30}>30</option>
                </select>
            </div>

        </div>
        <table className="table_default mb30">
            <thead>
                <tr>
                    <th>No</th>
                    <th><label htmlFor="chk_all" ><input type="checkbox" name="chk_all" id="chk_all" className="chk_default" /><span className="hide">회원 전체선택</span></label></th>
                    <th>인증</th>
                    <th>닉네임</th>
                    <th>이메일(아이디)</th>
                    <th>휴대폰번호</th>
                    <th>소속</th>
                    <th>최근 접속일</th>
                    <th>가입일</th>
                    {/* <th>미션 참여</th> */}
                    <th>편집</th>
                </tr>
            </thead>
            <tbody>
              {
               currentItems.map((item, index)=>(
                <tr key={item.id}>
                  <td>{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                      <td>
                        <input type='checkbox' className="chk_default" onChange={(e) => onChangeEach(e, item.id)} checked={checkList.includes(item.id)}/>
                      </td>
                      <td>{item.isVerified ? 'Y':'N'}</td>
                      <td>{item.nickname}</td>
                      <td>
                        {item.provider === 'local' && <span className="memb_id_cate"></span>}
                        {item.provider === 'facebook' && <span className="memb_id_cate" data-cate="fb"></span>}
                        {item.provider === 'kakao' && <span className="memb_id_cate" data-cate="kk"></span>}
                        {item.provider === 'naver' && <span className="memb_id_cate" data-cate="nv"></span>}
                        {item.user_id}
                      </td>
                      <td>{item.mobile}</td>
                      <td>{item.organization}</td>
                      <td><Moment format='YYYY.MM.DD'>{item.Logined}</Moment></td>
                      <td><Moment format='YYYY.MM.DD'>{item.created}</Moment></td>
                      {/* <td>20회</td> */}
                      <td><Link to={`/member/${item.id}/edit`} className="colgreen"><strong>수정</strong></Link></td>
                  </tr>
                ))
              }
            </tbody>
        </table>
        <div className="paging mb30">
            {/* <button type="button" className="paging_arrow paging_first"><span className="hide">첫 페이지로</span></button>
            <button type="button" className="paging_num active">1</button>
            <button type="button" className="paging_num">2</button>
            <button type="button" className="paging_arrow paging_end"><span className="hide">마지막 페이지로</span></button> */}
            <ReactPaginate
              breakLabel="..."
              nextLabel={<span className='paging_end pl30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first pr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName="paging_num paging_current"
              pageLinkClassName="paging_num"
              breakLinkClassName="paging_num"
              previousLinkClassName="paging_num"
              nextLinkClassName="paging_num"
            />
        </div>
        <div className="btns_wrap">
            {/* <Link to="/member/add"><button className="btn_green">신규 등록</button></Link> */}
            {/* <button className="btn_blue_line">선택 삭제</button> */}
            <button className="btn_blue_line">선택 탈퇴</button>
        </div>
    </div>}
    </>
  );
}

export default MemberList;
