import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import rewardsImg from '@assets/img/img_rw1.png';
import FormCKEditor from '@/components/FormCKEditor';

import * as Yup from 'yup';

import _ from 'lodash';
import moment from 'moment'

import randomBytes from 'randombytes';

import { mission, updateMission, deleteAdditionalImage } from '@actions/mission';
import { giveaways } from '@actions/giveaway';
import config from '@/config';
import Swal from 'sweetalert2'

const TeamEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { basePath } = useSelector((state) => state.common);
  const [isMissionShow, setIsMissionShow] = useState(false);
  const [isMissionCancel, setIsMissionCancel] = useState(false);
  const [isLoadRewards, setIsLoadRewards] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2022/12/21"));
  const [endDate, setEndDate] = useState(new Date("2022/12/22"));
  const [description, setDescription] = useState('');
  const [missionData, setMissionData] = useState(null);
  const [isFileUploadSpan, setIsFileUploadSpan] = useState(false);
  const [giveawayList, setGiveawayList] = useState(null);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    type: Yup.string(),
    title: Yup.string(),
    from_date: Yup.string(),
    to_date: Yup.string(),
    is_unlimit_date: Yup.boolean(),
    limit_time: Yup.number(),
    max_person: Yup.number().nullable(true),
    description: Yup.string(),
    giveaway: Yup.array(),
    giveaway_description: Yup.string()
  });

  const formOptions = {
    defaultValues: {
      _id: randomBytes(12).toString('hex'),
      title: '',
      description: ''
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  console.log('formData', formData)

  useEffect(() => {
    dispatch(giveaways({
        page: 1,
        limit: 100000
      }))
      .then((res) => {
        setGiveawayList(res.docs);
      })
  }, []);

  useEffect(() => {
    getMissionData();
  }, []);

  const getMissionData = () => {
    dispatch(mission(params.mission_id))
    .then((res) => {
      reset(res);

      setValue('from_date', moment(res.from_date).format("YYYY-MM-DD"))
      setValue('from_date_time', res.from_date.length > 10 ? moment(res.from_date).format("HH:mm") : '00:00:00')

      setValue('to_date', moment(res.to_date).format("YYYY-MM-DD"))
      setValue('to_date_time', res.to_date.length > 10 ? moment(res.to_date).format("HH:mm") : '23:59:00')

      setMissionData(res);
    })
  }

  const onSubmit = (data) => {
    if(!data.title){
      toast.error('제목을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    } else if (!data.from_date) {
      toast.error('미션 시작일을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    } else if (!data.limit_time) {
      toast.error('미션 시간을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    }

    const formData = new FormData();
    formData.append('type', 'team');
    formData.append("title", data.title);
    formData.append("from_date", `${moment(data.from_date).format("YYYY-MM-DD")} ${data.from_date_time ? data.from_date_time : '00:00:00'}`);
    // formData.append("to_date", `${moment(data.to_date).format("YYYY-MM-DD")} ${data.to_date_time ? data.to_date_time : '23:59:59'}`);
    formData.append("to_date", moment(`${moment(data.from_date).format("YYYY-MM-DD")} ${data.from_date_time ? data.from_date_time : '00:00:00'}`).add(data.limit_time, 'hours'));

    if(data.limit_time){
      formData.append("limit_time", data.limit_time);
    }

    if(data.max_team){
      formData.append("max_team", data.max_team);
    }

    if(data.max_team_persion){
      formData.append("max_team_persion", data.max_team_persion);
    }  

    if(data.min_team_persion){
      formData.append("min_team_persion", data.min_team_persion);
    }  

    if(data.cancel_message){
      formData.append("cancel_message", data.cancel_message);
    }    

    if(data.intro_image_src[0]){
      formData.append("intro_image", data.intro_image_src[0]);
    }

    if(data.additional_image1_src[0]){
      formData.append("additional_image1", data.additional_image1_src[0]);
    }

    if(data.additional_image2_src[0]){
        formData.append("additional_image2", data.additional_image2_src[0]);
    }

    if(data.additional_image4_src[0]){
        formData.append("additional_image4", data.additional_image4_src[0]);
    }

    if(data.additional_image5_src[0]){
        formData.append("additional_image5", data.additional_image5_src[0]);
    }

    if(data.additional_image6_src[0]){
      formData.append("additional_image6", data.additional_image6_src[0]);
    }

    if(data.additional_image7_src[0]){
      formData.append("additional_image7", data.additional_image7_src[0]);
    }

    if(data.additional_image8_src[0]){
      formData.append("additional_image8", data.additional_image8_src[0]);
    }

    if(data.additional_image9_src[0]){
      formData.append("additional_image9", data.additional_image9_src[0]);
    }

    if(data.additional_image10_src[0]){
      formData.append("additional_image10", data.additional_image10_src[0]);
    }

    formData.append("description", data.description);
    formData.append("giveaway", data.giveaway);
    formData.append("giveaway_description", data.giveaway_description);

    dispatch(updateMission(params.mission_id, formData))
    .then((res) => {
      getMissionData();
      toast.success('미션정보를 수정했습니다..', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  //delete additional image
  const onDeleteAdditionalImage = (idx) => {
    Swal.fire({
      text: `${idx}번 추가이미지를 삭제 하시겠습니까?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: '아니요',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAdditionalImage(params.mission_id, idx))
        .then(res=>{
          getMissionData()
          toast.success('추가 이미지를 삭제했습니다.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        })
      }
    })
  }

  return (
    <>
      {missionData && <div className="container_adm">
          <div className="container_adm_inner">
            <div className="adm_title_sm mb30">미션 수정</div>
            <form id="form_missionteamAdd" onSubmit={handleSubmit(onSubmit)}> 
                <div className="content_box mb30">
                  <h2 className="colblue">기본 정보</h2>
                  <fieldset className="flex_wrap flex_wrap2">
                      <div className="two-third">
                          <label htmlFor="ms_name" className="required">제목</label>
                          <input type="text" id="ms_name" {...register('title')} className="input_default" />
                      </div>
                      <div className="two-third">
                          <label htmlFor="ms_date_from" className="required">미션 시작</label>
                          <div className="ms_datepicker">
                              <label htmlFor="ms_date_from">
                                <DatePicker
                                    selected={new Date(formData.from_date)}
                                    onChange={(date) => setValue('from_date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    selectsStart
                                    startDate={new Date(formData.from_date)}
                                    endDate={new Date(formData.to_date)}
                                />
                                {/* input time */}
                                <input type='time' {...register('from_date_time')} className="input_default" />
                              </label>
                          </div>
                      </div>
                      <div className="one-third">
                          <label htmlFor="ms_date_from" className="required">미션 시간</label>
                          <input type="number" id="ms_duration" className="input_default" {...register("limit_time")} min={1} placeholder="시간 기준 (ex. 5)" />
                      </div>
                      <div className="one-third">
                          <label htmlFor="ms_date_from" className="required">팀 수</label>
                          <input type="text" id="ms_date_from" className="input_default" placeholder="숫자만 입력해 주세요 (생설 할 팀 수)" {...register("max_team")} min={2} />
                      </div>
                      <div className="one-third">
                          <label htmlFor="ms_duration" className="required">한 팀당 인원 수</label>
                          <input type="text" id="ms_duration" className="input_default mr20"  placeholder="숫자만 입력해 주세요 (팀 구성 인원)" {...register("max_team_persion")} min={formData.min_team_persion} />
                      </div>
                      <div className="two-third">
                          <label htmlFor="ms_duration" className="required">한 팀당 최소 인원 수</label>
                          <input type="text" id="ms_duration" className="input_default mr20"  placeholder="숫자만 입력해 주세요 (팀 최소 인원)" {...register("min_team_persion")} min={2} max={formData.max_team_persion} />
                          <small className="colred nowrap">* 미션 시작 전, 최소 인원이 도달하지 못하는 경우 게임이 자동 중단 처리됩니다.</small>
                      </div>
                      <div className="two-third">
                          <label htmlFor="ms_duration" className="required">중단 메세지</label>
                          <input type="text" id="ms_duration" className="input_default"  placeholder="인원 미달으로 미션 중단 시 노출 할 메세지를 입력해 주세요" {...register("cancel_message")}/>
                      </div>
                  </fieldset>
                </div>
                <div className="content_box mb30">
                    <h2 className="colblue">미션 상세 정보</h2>
                    <fieldset className="flex_wrap flex_wrap2">
                    <div className="two-third">
                            <label htmlFor="ms_name" className="required">대표 이미지</label>
                            <div className="file_upload_wrap" onClick={()=>setIsFileUploadSpan(true)}>
                                <span style={{display: isFileUploadSpan ? 'none' : 'block'}}>jpg, png 파일만 업로드 가능합니다.</span>
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("intro_image_src")}  accept="image/png, image/jpg" />
                                </label>
                            </div>
                        </div>
                        {formData?.intro_image?.server_filename && <div className="two-third">
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.intro_image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                          {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지1</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image1_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image1?.server_filename && <div className="two-third">
                          <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(1)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image1?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지2</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image2_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image2?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(2)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image2?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지3</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image3_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image3?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(3)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image3?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지4</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image4_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image4?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(4)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image4?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지5</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image5_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image5?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(5)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image5?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지6</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image6_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image6?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(6)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image6?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지7</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image7_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image7?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(7)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image7?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지8</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image8_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image8?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(8)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image8?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지9</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image9_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image9?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(9)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image9?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">추가 이미지10</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" {...register("additional_image10_src")} accept="image/png, image/jpg" />
                                </label>
                            </div>
                            {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                        </div>
                        {formData?.additional_image10?.server_filename && <div className="two-third">
                        <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(10)}><span className="hide">이미지 삭제</span></button>
                          <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image10?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                        </div>}
                        <div className="three-third">
                            <label htmlFor="ms_date_from" className="required">미션 설명</label>
                            <FormCKEditor
                                data={formData.description}
                                handleChange={(value) => {
                                    setValue('description', value);
                                }}
                            />
                        </div>
                    </fieldset>
                </div>
                <div className="content_box mb30">
                    <h2 className="colblue">경품 상세 정보</h2>
                    <fieldset className="flex_wrap flex_wrap2">
                    <div className="three-third">
                      <div className="rewards_list_wrap mb30">
                        {
                          giveawayList && giveawayList.map((item, ri)=><div className="reward_item" key={item.id}>
                          <label htmlFor={`reward_item_${item.id}`}>
                              <input type="checkbox" name={`reward_item_${item.id}`} id={`reward_item_${item.id}`} className="chk_default" {...register("giveaway")} value={item.id}/>
                              <img src={`${config.apiServerHost}/giveaways/attachment/${item?.image?.server_filename}`} alt="" />
                          </label>
                          <p>{item.title}</p>
                        </div>)
                        }
                      </div>
                    </div>
                    <div className="three-third">
                        <label htmlFor="ms_date_from">경품 안내 설명</label>
                        <FormCKEditor
                            data={formData.giveaway_description}
                            handleChange={(value) => {
                                setValue('giveaway_description', value);
                            }}
                        />
                    </div>
                    </fieldset>
                </div>
                <div className="btns_wrap">
                    <button type="button" className="btn_green_line" onClick={()=>setIsMissionCancel(true)}>취소</button>
                    <button type="submit" className="btn_green">수정</button>
                </div>
              </form>
            </div>
            <div className="container_adm_inner" style={{display:'none'}}>
                <div className="adm_title_sm mb30">미션 추가</div>
                <div className="content_box mb30">
                    <div className="ms_step1_info">
                        <h2 className="mb30">광주 무등산 서석대로 가서 황금열쇠를 찾아보자!</h2>
                        <div>
                            <strong className="colblue">미션 기간</strong>
                            <span>2022.09.21~2022.12.31</span>
                            <strong className="colblue">미션 시간</strong>
                            <span>3시간</span>
                            <strong className="colblue">팀 수</strong>
                            <span>5팀</span>
                        </div>
                    </div>
                </div>
                <form id="ms_add">
                    <div className="content_box mission_box mb30">
                        <button className="btn_close"><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A1</h2>
                            <input type="text" className="input_default" placeholder="점수 입력" />
                        </div>
                        <div className="mission_box__right">
                            <div className="right_line1">
                                <label htmlFor="ms_type">
                                    <select name="ms_type" id="ms_type" className="select_default colblue">
                                        <option value="0" selected>객관식 퀴즈</option>
                                        <option value="1">주관식 퀴즈</option>
                                        <option value="2">이미지 퀴즈</option>
                                        <option value="3">설명</option>
                                    </select>
                                </label>
                                <input type="text" value="32526" id="ms_postcode" className="input_default" />
                                <button className="btn_search_lg"><span className="hide">주소 검색</span></button>
                                <input type="text" value="광주광역시 동구 예술길24-1" id="ms_address" className="input_default" />
                            </div>
                            <div className="right_line2">
                                <label htmlFor="ms_addDesc">
                                    <input 
                                        type="checkbox" 
                                        name="ms_addDesc" 
                                        id="ms_addDesc" 
                                        className="chk_default mr20"
                                        />
                                    <strong>미션 설명 추가</strong></label>
                                    
                            </div>
                            <div className="right_line3">
                                <label htmlFor="ms_type1_q" className="right_label">Q 질문</label>
                                <input type="text" className="input_default" id="ms_type1_q" value="무등산의 정상은 어디일까요?" />
                            </div>
                            <div className="right_line4">
                                <label className="right_label">A 정답</label>
                                <div className="ms_type0_a_wrap">
                                    <div className="type0_a_item">
                                        <label htmlFor="type1_a[0]"><input type="checkbox" name="" id="" className="chk_default" /></label>
                                        <strong>1</strong>
                                        <input type="text" className="input_default" id="" value="서석대" />
                                        <select name="type1_a[1]_next" id="type1_a[1]_next" className="select_default">
                                            <option value="0" selected>다음 문항</option>
                                            <option value="1">A3 무등산에서 먹고 싶은 음식은 무엇인가요?</option>
                                        </select>
                                    </div>
                                    <div className="type0_a_item">
                                        <label htmlFor="type1_a[0]"><input type="checkbox" name="" id="" className="chk_default" /></label>
                                        <strong>2</strong>
                                        <input type="text" className="input_default" id="" value="중머리재" />
                                        <select name="type1_a[1]_next" id="type1_a[2]_next" className="select_default">
                                            <option value="0">다음 문항</option>
                                            <option value="1" selected>A3 무등산에서 먹고 싶은 음식은 무엇인가요?</option>
                                        </select>
                                    </div>
                                    <div className="type0_a_item type0_a_item_last">
                                        <label htmlFor="type0_a[0]"><input type="checkbox" name="" id="" className="chk_default" /></label>
                                        <strong>3</strong>
                                        <input type="text" className="input_default" id="" />
                                        <select name="type1_a[1]_next" id="type1_a[3]_next" className="select_default">
                                            <option value="0">다음 문항</option>
                                            <option value="1">A3 무등산에서 먹고 싶은 음식은 무엇인가요?</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="right_line5">
                                <label className="right_label">힌트</label>
                                <div className="ms_type0_hint">
                                    <input type="text" className="input_default" value="유네스코에서 지정한 문화재가 있어요!" />
                                    <input type="text" className="input_default" placeholder="미작성 시 노출되지 않음" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button">추가</button>
                            <button type="button">복제</button>
                        </div>
                    </div>
                    <div className="content_box mission_box mission_box2 mb30">
                        <button className="btn_close"><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A2</h2>
                            <input type="text" className="input_default" placeholder="점수 입력" />
                        </div>
                        <div className="mission_box__right">
                            <div className="right_line1">
                                <label htmlFor="ms_type">
                                    <select name="ms_type" id="ms_type" className="select_default colblue">
                                        <option value="0">객관식 퀴즈</option>
                                        <option value="1" selected>주관식 퀴즈</option>
                                        <option value="2">이미지 퀴즈</option>
                                        <option value="3">설명</option>
                                    </select>
                                </label>
                                <input type="text" value="32526" id="ms_postcode" className="input_default" />
                                <button className="btn_search_lg"><span className="hide">주소 검색</span></button>
                                <input type="text" value="광주광역시 동구 예술길24-1" id="ms_address" className="input_default" />
                            </div>
                            <div className="right_line2">
                                <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" checked /><strong>미션 설명 추가</strong></label>
                                <FormCKEditor
                            data={description}
                            handleChange={setDescription}
                            />
                            </div>
                            <div className="right_line3">
                                <label htmlFor="type_2_ox" className="right_label">답 유무</label>
                                <div>
                                    <input type="radio" name="type_2_ox" id="type_2_o" className="chk_circle mr10" checked /><label htmlFor="type_1_o" className="mr30">정답 있음</label>
                                    <input type="radio" name="type_2_ox" id="type_2_x" className="chk_circle mr10" /><label htmlFor="type_1_x">정답 없음</label>
                                </div>
                            </div>
                            <div className="right_line3">
                                <label htmlFor="ms_type0_q" className="right_label">Q 질문</label>
                                <input type="text" className="input_default" id="ms_type2_q" value="무등산의 정상은 어디일까요?" />
                            </div>
                            <div className="right_line4">
                                <label htmlFor="" className="right_label">A 정답</label>
                                <div className="ms_type1_a_wrap">
                                    <input type="text" className="input_default mr20" value="무등산지질공원, 무등산세계지질공원" />
                                    <select name="type2_a_next" id="type2_a_next" className="select_default">
                                        <option value="0">다음 문항</option>
                                        <option value="1">A4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="right_line5">
                                <label className="right_label">힌트</label>
                                <div className="ms_type0_hint">
                                    <input type="text" className="input_default" value="유네스코에서 지정한 문화재가 있어요!" />
                                    <input type="text" className="input_default" placeholder="미작성 시 노출되지 않음" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button">추가</button>
                            <button type="button">복제</button>
                        </div>
                    </div>
                    <div className="content_box mission_box mission_box2 mb30">
                        <button className="btn_close"><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A3</h2>
                            <input type="text" className="input_default" placeholder="점수 입력" />
                        </div>
                        <div className="mission_box__right">
                            <div className="right_line1">
                                <label htmlFor="ms_type">
                                    <select name="ms_type" id="ms_type" className="select_default colblue">
                                        <option value="0">객관식 퀴즈</option>
                                        <option value="1" selected>주관식 퀴즈</option>
                                        <option value="2">이미지 퀴즈</option>
                                        <option value="3">설명</option>
                                    </select>
                                </label>
                                <input type="text" value="32526" id="ms_postcode" className="input_default" />
                                <button className="btn_search_lg"><span className="hide">주소 검색</span></button>
                                <input type="text" value="광주광역시 동구 예술길24-1" id="ms_address" className="input_default" />
                            </div>
                            <div className="right_line2">
                                <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" checked /><strong>미션 설명 추가</strong></label>
                                <FormCKEditor
                            data={description}
                            handleChange={setDescription}
                            />
                            </div>
                            <div className="right_line3">
                                <label htmlFor="type_2_ox" className="right_label">답 유무</label>
                                <div>
                                    <input type="radio" name="type_2_ox" id="type_2_o" className="chk_circle mr10" /><label htmlFor="type_1_o" className="mr30">정답 있음</label>
                                    <input type="radio" name="type_2_ox" id="type_2_x" className="chk_circle mr10" checked /><label htmlFor="type_1_x">정답 없음</label>
                                </div>
                            </div>
                            <div className="right_line3">
                                <label htmlFor="ms_type0_q" className="right_label">Q 질문</label>
                                <input type="text" className="input_default" id="ms_type2_q" value="무등산에서 먹고 싶은 음식은 무엇인가요?" />
                            </div>
                            <div className="right_line4">
                                <label htmlFor="" className="right_label">A 정답</label>
                                <div className="ms_type1_a_wrap">
                                    <select name="type2_a_next" id="type2_a_next" className="select_default">
                                        <option value="0">다음 문항</option>
                                        <option value="1">A4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="right_line5">
                                <label className="right_label">힌트</label>
                                <div className="ms_type0_hint">
                                    <input type="text" className="input_default" value="유네스코에서 지정한 문화재가 있어요!" />
                                    <input type="text" className="input_default" placeholder="미작성 시 노출되지 않음" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button">추가</button>
                            <button type="button">복제</button>
                        </div>
                    </div>
                    <div className="content_box mission_box mission_box3 mb30">
                        <button className="btn_close"><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A4</h2>
                            <input type="text" className="input_default" placeholder="점수 입력" />
                        </div>
                        <div className="mission_box__right">
                            <div className="right_line1">
                                <label htmlFor="ms_type">
                                    <select name="ms_type" id="ms_type" className="select_default colblue">
                                        <option value="0">객관식 퀴즈</option>
                                        <option value="1">주관식 퀴즈</option>
                                        <option value="2" selected>이미지 퀴즈</option>
                                        <option value="3">설명</option>
                                    </select>
                                </label>
                                <input type="text" value="32526" id="ms_postcode" className="input_default" />
                                <button className="btn_search_lg"><span className="hide">주소 검색</span></button>
                                <input type="text" value="광주광역시 동구 예술길24-1" id="ms_address" className="input_default" />
                            </div>
                            <div className="right_line2">
                                <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" /><strong>미션 설명 추가</strong></label>
                            </div>
                            <div className="right_line3">
                                <label htmlFor="ms_type3_q" className="right_label">Q 질문</label>
                                <input type="text" className="input_default" id="ms_type3_q" value="무등산 입구 사진을 찍어주세요!" />
                            </div>
                            <div className="right_line4">
                                <label className="right_label"></label>
                                <div className="ms_type3_a_wrap">
                                    <select name="type3_a_next" id="type3_a_next" className="select_default">
                                        <option value="0">다음 문항</option>
                                        <option value="1">A4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="right_line5">
                                <label className="right_label">힌트</label>
                                <div className="ms_type0_hint">
                                    <input type="text" className="input_default" placeholder="미작성 시 노출되지 않음" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button">추가</button>
                            <button type="button">복제</button>
                        </div>
                    </div>
                    <div className="content_box mission_box mission_box4 mb30">
                        <button className="btn_close"><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A5</h2>
                            <input type="text" className="input_default" placeholder="점수 입력" />
                        </div>
                        <div className="mission_box__right">
                            <div className="right_line1">
                                <label htmlFor="ms_type">
                                    <select name="ms_type" id="ms_type" className="select_default colblue">
                                        <option value="0">객관식 퀴즈</option>
                                        <option value="1">주관식 퀴즈</option>
                                        <option value="2">이미지 퀴즈</option>
                                        <option value="3" selected>설명</option>
                                    </select>
                                </label>
                                <input type="text" value="32526" id="ms_postcode" className="input_default" />
                                <button className="btn_search_lg"><span className="hide">주소 검색</span></button>
                                <input type="text" value="광주광역시 동구 예술길24-1" id="ms_address" className="input_default" />
                            </div>
                            <div className="right_line2">
                                <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" checked /><strong>미션 설명 추가</strong></label>
                                <FormCKEditor
                            data={description}
                            handleChange={setDescription}
                            />
                            </div>
                            <div className="right_line3">
                                <label className="right_label">제목</label>
                                <input type="text" className="input_default" id="ms_type3_q" placeholder="제목을 입력해 주세요" />
                            </div>
                            <div className="right_line4">
                                <label className="right_label">간단 설명</label>
                                <div className="ms_type3_a_wrap">
                                <textarea className='textarea_default mb30' rows="5"></textarea>
                                    <select name="type3_a_next" id="type3_a_next" className="select_default">
                                        <option value="0">다음 문항</option>
                                        <option value="1">A4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button">추가</button>
                            <button type="button">복제</button>
                        </div>
                    </div>
                    <div className="btns_wrap">
                        <button className="btn_green_line"  onClick={()=>{history.navigate(basePath ? `/${basePath}/team` : '/team'); }}>취소</button>
                        <button type="button" className="btn_green" onClick={()=>setIsMissionShow(true)}>등록완료</button>
                    </div>
                </form>
            </div>
        </div>}
        <div id="modalRewards" className="modal" style={{display: isLoadRewards ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content modal_lg'>
                    <div className="modal_header">
                        <h2>경품 불러오기</h2>
                        <button className="btn_close" onClick={()=>setIsLoadRewards(false)}><span className="hide">창 닫기</span></button>
                    </div>
                    <div className="modal_body">
                        <form>
                            <div className="modal_search_wrap">
                                <input type="text" className="input_default" placeholder="경품 이름을 입력해 주세요" />
                                <button className="btn_search_lg"><span className="hide">검색</span></button>
                            </div>
                            <div className="modalRewards_wrap">
                                <div className="modalReward">
                                    <label htmlFor="chk_reward">
                                        <input type="checkbox" name="chk_reward" id="chk_reward" />
                                        <img src={rewardsImg} alt="" />
                                    </label>
                                    <small>스타벅스 아메리카노 T*2</small>
                                </div>
                                <div className="modalReward">
                                    <label htmlFor="chk_reward">
                                        <input type="checkbox" name="chk_reward" id="chk_reward" />
                                        <img src={rewardsImg} alt="" />
                                    </label>
                                    <small>스타벅스 아메리카노 T*2</small>
                                </div>
                                <div className="modalReward">
                                    <label htmlFor="chk_reward">
                                        <input type="checkbox" name="chk_reward" id="chk_reward" />
                                        <img src={rewardsImg} alt="" />
                                    </label>
                                    <small>스타벅스 아메리카노 T*2</small>
                                </div>
                                <div className="modalReward">
                                    <label htmlFor="chk_reward">
                                        <input type="checkbox" name="chk_reward" id="chk_reward" />
                                        <img src={rewardsImg} alt="" />
                                    </label>
                                    <small>스타벅스 아메리카노 T*2</small>
                                </div>
                            </div>
                            <div className="btns_wrap">
                                <button className="btn_green">등록</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div id="modalMsshow" className="modal" style={{display: isMissionShow ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <div className="modal_header">
                        <button className="btn_close" onClick={()=>setIsMissionShow(false)}><span className="hide">창 닫기</span></button>
                    </div>
                    <form>
                        <div className="modal_body">
                            <p className="mb30">미션을 바로 노출시키겠습니까?</p>
                            <div className="radio_wrap">
                                <label htmlFor="ms_yes" className="mr30">
                                    <input type="radio" name="ms_show" id="ms_yes" className="chk_circle mr10" checked />노출
                                </label>
                                <label htmlFor="ms_no">
                                    <input type="radio" name="ms_show" id="ms_no" className="chk_circle mr10" />숨김
                                </label>
                            </div>
                        </div>
                        <div className="modal_footer">
                            <button type="submit" className="btn_green">등록 완료</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modalMsCancel" className="modal" style={{display: isMissionCancel ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <div className="modal_header">
                        <button className="btn_close" onClick={()=>setIsMissionCancel(false)}><span className="hide">창 닫기</span></button>
                    </div>
                    <form>
                        <div className="modal_body">
                            <p style={{paddingTop:'30px'}}>미션 등록을 취소하시겠습니까?</p>
                        </div>
                        <div className="modal_footer">
                            <button className="btn_green" onClick={()=>{history.navigate(basePath ? `/${basePath}/team` : '/team'); }}>확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  );
}

export default TeamEdit;
