const apiServerHost = process.env.REACT_APP_API_URL;
const websocketHost = process.env.REACT_APP_WEBSOCKET_URL; 
const baseUrl = process.env.REACT_APP_BASE_URL;
const kakaoAppKey = process.env.REACT_APP_KAKAO_REST_API_KEY;

const config = {
  apiServerHost,
  websocketHost,
  kakaoAppKey,
  baseUrl
}

export default config;
