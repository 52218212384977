import api from '@/services/api';
import queryString from 'query-string';

class GiveawayService {
  createGiveaway(params) {
    return api.post('/giveaways', params)
  }

  giveaway(giveaway_id) {
    return api.get(`/giveaways/${giveaway_id}`)
  }

  giveaways(params) {
    return api.get(`/giveaways?${queryString.stringify(params)}`)
  }

  updateGiveaway(giveaway_id, params) {
    return api.put(`/giveaways/${giveaway_id}`, params)
  }

  deleteGiveaway(giveaway_id) {
    return api.delete(`/giveaways/${giveaway_id}`)
  }
}

export default new GiveawayService();
