import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { logout } from "@actions/account";
import history from '@helpers/history';

function Header(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div>
      <header id="header">
          <div className="header_inner">
              <NavLink to="/"><h1><img src={require('@assets/img/logo.svg').default} alt="로고" /></h1></NavLink>
              <div className="header_inner_right">
                  <span>{currentUser.user.nickname}</span>
                  <Link to="#" onClick={()=>logOut()}>로그아웃</Link>
              </div>
          </div>
      </header>
      <div id="gnb_wrap">
          <nav className="gnb">
              <ul>
                  <li><NavLink to ="/">기본설정</NavLink></li>
                  <li><NavLink to="/member">회원관리</NavLink></li>
                  <li><NavLink to="/solo">1인 미션 투어</NavLink></li>
                  <li><NavLink to="/team">단체 미션 투어</NavLink></li>
                  <li><NavLink to="/stamp">스탬프투어</NavLink></li>
                  <li><NavLink to="/spots">광주관광지</NavLink></li>
                  <li><NavLink to="/rewards">경품관리</NavLink></li>
                  <li><NavLink to="/notice">공지사항 관리</NavLink></li>
                  <li><NavLink to="/event">이벤트 관리</NavLink></li>
                  <li><NavLink to="/terms">약관 관리</NavLink></li>
              </ul>
          </nav>
      </div>
    </div>
  );
}

export default Header;
