import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';

import * as Yup from 'yup';

import { giveaways } from '@actions/giveaway';
import config from '@/config';

const RewardsList = () => {
  const dispatch = useDispatch();
  
  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [pageLimit, setPageLimit] = useState(20);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  
  const [searchText, setSearchText] = useState('');
  const [searchReset, setSearchReset] = useState(false);

  useEffect(() => {
    getList();
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if(searchReset){
      handleSearch()
    }
  }, [searchReset]);

  const getList = () => {
    if(searchReset){
      setSearchReset(false);
    }

    dispatch(giveaways({
      page: currentPage,
      limit: pageLimit,
      text: searchText
    }))
    .then((res) => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
      setCheckList([]);
    })
  }

  const handleSearch = () => {
    setCurrentPage(1);
    getList();
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };


  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }


  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(value);
    setCurrentPage(1);
  }

  return (
    <>
        <div className="container_adm">
            <div className="adm_title_wrap mb30">
                <div className="adm_title__left">
                    <div className="adm_title mr20">경품관리</div>
                </div>
                <div className="adm_title__right">
                    <div className="search_wrap">
                        <label htmlFor="검색어 입력" className="hide">검색어 입력</label>
                        <input type="text" id="search_text" placeholder="검색어를 입력해 주세요"/>
                        <button type="button" className="btn_search"><span className="hide">검색</span></button>
                    </div>
                    <select name="table_row" id="table_row">
                        <option value="12">12</option>
                        <option value="24">24</option>
                        <option value="36">36</option>
                    </select>
                </div>
            </div>
            {currentItems && <div className="rewards_list_wrap mb30">
                {
                currentItems.map((item, index)=>(<div className="reward_item">
                    <label htmlFor={`reward_item_${item.id}`}>
                        <input type="checkbox" name={`reward_item_${item.id}`} id={`reward_item_${item.id}`} className="chk_default" />
                        <img src={`${config.apiServerHost}/giveaways/attachment/${item?.image?.server_filename}`} alt="" onError={e=>e.target.src = 'https://via.placeholder.com/500'}/>
                    </label>
                    <p><Link to={`/rewards/${item.id}/edit`}>{item.title}</Link></p>
                </div>
                ))}
            </div>}
            <div className="paging mb30">
                {/* <button type="button" className="paging_arrow paging_first"><span className="hide">첫 페이지로</span></button>
                <button type="button" className="paging_num active">1</button>
                <button type="button" className="paging_num">2</button>
                <button type="button" className="paging_arrow paging_end"><span className="hide">마지막 페이지로</span></button> */}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<span className='paging_end pl30'></span>}
                  onPageChange={handlePageClick}
                  forcePage={ currentPage - 1 }
                  pageRangeDisplayed={3}
                  pageCount={totalPageCount}
                  previousLabel={<span className='paging_first pr30'></span>}
                  renderOnZeroPageCount={null}
                  activeLinkClassName="paging_num paging_current"
                  pageLinkClassName="paging_num"
                  breakLinkClassName="paging_num"
                  previousLinkClassName="paging_num"
                  nextLinkClassName="paging_num"
                />
            </div>
            <div className="btns_wrap">
                <button className="btn_blue_line">선택 삭제</button>
                {/* <button className="btn_blue_line">선택 복사</button> */}
                <Link to="/rewards/add"><button className="btn_green">추가</button></Link>
            </div>
        </div>
    </>
  );
}

export default RewardsList;
