import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import FormCKEditor from '@/components/FormCKEditor';
import _ from 'lodash';

import randomBytes from 'randombytes';

import { mission, updateQuiz } from '@actions/mission';
import DaumPostcode from 'react-daum-postcode';
import Moment from 'react-moment';
import {Map, MapMarker} from 'react-kakao-maps-sdk';
// Using an ES6 transpiler like Babel
import Slider from 'react-rangeslider'

// To include the default styles
import 'react-rangeslider/lib/index.css'

const SoloQuiz = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [missionData, setMissionData] = useState(null);
  const [isMissionShow, setIsMissionShow] = useState(false);
  const [isMissionCancel, setIsMissionCancel] = useState(false);
  const { kakao } = window

  const validationSchema = Yup.object().shape({
    total_score: Yup.number(),
    pass_score: Yup.number(),
    quiz: Yup.array()
  });

  const MultipleTemplate = {
    type: 'multiple',
    question: '',
    address: '',
    mission: params.mission_id,
    isPostPop: false,
    multiple: [
      {
        answer: false,
        text: '',
        forward_index: ''
      },
      {
        answer: false,
        text: '',
        forward_index: ''
      },
      {
        answer: false,
        text: '',
        forward_index: ''
      },
      {
        answer: false,
        text: '',
        forward_index: ''
      },
      {
        answer: false,
        text: '',
        forward_index: ''
      }
    ],
    hint: [],
    score: 0,
    is_exists_description: false,
    coordinate: {},
    description: '',
    isNew: true,
    removed: false
  }
  
  const EssayTemplate = {
    type: 'essay',
    question: '',
    address: '',
    mission: params.mission_id,
    isPostPop: false,
    essay: {
      answer: '',
      forward_index: ''
    },
    hint: [],
    score: 0,
    is_exists_description: false,
    coordinate: {},
    description: '',
    isNew: true,
    removed: false
  }

  const ImageTemplate = {
    type: 'image',
    question: '',
    address: '',
    mission: params.mission_id,
    isPostPop: false,
    image: {
      forward_index: ''
    },
    hint: [],
    score: 0,
    is_exists_description: false,
    coordinate: {},
    description: '',
    isNew: true,
    removed: false
  }

  const oxTemplate = {
    type: 'ox',
    question: '',
    address: '',
    mission: params.mission_id,
    isPostPop: false,
    ox: [{
      answer: false,
      text: 'O',
      forward_index: ''
    },{
      answer: false,
      text: 'X',
      forward_index: ''
    }],
    hint: [],
    score: 0,
    is_exists_description: false,
    coordinate: {},
    description: '',
    isNew: true,
    removed: false
  }

  const formOptions = {
    defaultValues: {
      total_score: 0,
      pass_score: 0,
      quiz: []
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const { fields, move, append, remove, update, insert } = useFieldArray({ name: 'quiz', control });

  const formData = watch();

  useEffect(() => {
    dispatch(mission(params.mission_id))
      .then((res) => {
        reset(res);
        setMissionData(res);
      })
  }, []);

  const onSubmit = (data) => {
    dispatch(updateQuiz(params.mission_id, data))
    .then((res) => {
      console.log(res)
      // setIsMissionShow(true)
    //   setIsRewardsUp(true)
      // history.navigate(`/solo`, { replace: true })
      toast.success('퀴즈를 수정했습니다..', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const setCompleteAddress = (i, address) => {
    setValue(`quiz.${i}.address`, address)

    const geocoder = new kakao.maps.services.Geocoder();

    geocoder.addressSearch(address, (result, status) => {
      if (status == kakao.maps.services.Status.OK) {

        const coordinate = {
          lat: result[0].y,
          lng: result[0].x,
        }

        setValue(`quiz.${i}.coordinate`, coordinate)
        setValue(`quiz.${i}.isPostPop`, false)

        // const container = document.querySelector('.map-'+i);
        
        // const options = {
        //     center: new kakao.maps.LatLng(coordinate.lat, coordinate.lng),
        //     level: 3
        // };

        // const map = new kakao.maps.Map(container, options);

        // var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

        // var marker = new kakao.maps.Marker({
        //     map: map,
        //     position: coords
        // });

        // var infowindow = new kakao.maps.InfoWindow({
        //     content: '<div style="width:150px;text-align:center;padding:6px 0;">'+address+'</div>'
        // });
        // infowindow.open(map, marker);

        // map.setCenter(coords);
      } else {
        setValue(`quiz.${i}.address`, '')
      }
    });    
  }

  const removeQuiz = (i) => {
    //Multiple Essay Image
    const quiz = formData.quiz[i];

    console.log('quiz', quiz)

    for (let index = 0; index < formData.quiz.length; index++) {
      const element = formData.quiz[index];
      // console.log(element)
      if(element.type === 'multiple') { 
        // element.multiple
        for (let index2 = 0; index2 < element.multiple.length; index2++) {
          const element2 = element.multiple[index2];
          if(quiz._id === element2.forward_index) {
            setValue(`quiz.${index}.multiple.${index2}.forward_index`, '')
          }
          console.log('element2', element2)
        }
      } else if(element.type === 'ox') { 
        // element.multiple
        for (let index2 = 0; index2 < element.ox.length; index2++) {
          const element2 = element.ox[index2];
          if(quiz._id === element2.forward_index) {
            setValue(`quiz.${index}.ox.${index2}.forward_index`, '')
          }
          console.log('element2', element2)
        }
      } else if(element.type === 'essay') { 
        if(quiz._id === element.essay.forward_index) {
          setValue(`quiz.${index}.essay.forward_index`, '')
        }
      } else if(element.type === 'image') { 
        if(quiz._id === element.image.forward_index) {
          setValue(`quiz.${index}.image.forward_index`, '')
        }
      }
    }

    if(quiz.isNew) {
      remove(i)
    } else {
      quiz.removed = true;
      quiz.removed_date = new Date();
    }
  }

  return (
    <>
        {missionData && <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">퀴즈 수정</div>
                <div className="content_box mb30">
                    <div className="ms_step1_info">
                        <h2 className="mb30">{missionData.title}</h2>
                        <div>
                            <strong className="colblue">미션 기간</strong>
                            <span><Moment format='YYYY.MM.DD'>{missionData.from_date}</Moment> ~ <Moment format='YYYY.MM.DD'>{missionData.to_date}</Moment></span>
                            <strong className="colblue">미션 시간</strong>
                            <span>{missionData.limit_time}시간</span>
                            <strong className="colblue">최대 인원</strong>
                            <span>{missionData.max_person}명</span>
                        </div>
                    </div>
                </div>
                  <form id="ms_add" onSubmit={handleSubmit(onSubmit)}>
                    {fields.length === 0 && <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button" onClick={()=>{
                              append({
                                _id: randomBytes(12).toString('hex'),
                                ...MultipleTemplate})
                            }}>객관식</button>
                            <button type="button" onClick={()=>{
                              append({
                                _id: randomBytes(12).toString('hex'),
                                ...EssayTemplate})
                            }}>주관식</button>
                            <button type="button" onClick={()=>{
                              append({
                                _id: randomBytes(12).toString('hex'),
                                ...ImageTemplate})
                            }}>이미지</button>
                            <button type="button" onClick={()=>{
                              append({
                                _id: randomBytes(12).toString('hex'),
                                ...oxTemplate})
                            }}>ox</button>
                        </div>
                    </div>}
                    {
                      formData.quiz && _.filter(formData.quiz, r=>!r.removed).map((item, i) => (
                        <div key={item._id}>
                        {item.type === 'multiple' && <div className="content_box mission_box mb30">
                        <button type='button' className="btn_close" onClick={()=>removeQuiz(i)}><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A{i+1}</h2>
                            <input type="number" className="input_default" {...register(`quiz.${i}.score`)} placeholder="점수" min={0} onChange={()=>setValue('pass_score', 0)}/>
                        </div>
                        <div className="mission_box__right">
                            <div className="input_address_wrap mb30">
                              <div className="input_postcode">
                                <input type="text" id="acc_add2" className="input_default" {...register(`quiz.${i}.address`)} readOnly/>
                                <button type='button' onClick={()=>setValue(`quiz.${i}.isPostPop`, true)}>주소등록</button>
                                {item.isPostPop && 
                                  <DaumPostcode 
                                      onComplete={e=>setCompleteAddress(i, e.address)}
                                      autoClose={false}
                                      defaultQuery={item?.address}
                                      style={{
                                        position:'absolute',
                                        display:'block',
                                        top:'0',
                                        left:'0',
                                        width:'100%',
                                        height:'50vh',
                                        zIndex: 9,
                                      }}
                                />}
                              </div>
                              {item.coordinate.lat && item.coordinate.lng && <Map
                                center={{
                                  lat: item.coordinate.lat,
                                  lng: item.coordinate.lng,
                                }}
                                style={{
                                  width: "100%",
                                  height: "350px",
                                }}
                                level={3}
                              >
                                <MapMarker
                                  position={{
                                    lat: item.coordinate?.lat,
                                    lng: item.coordinate?.lng,
                                  }}
                                />
                              </Map>}
                            </div>
                            <div className="right_line3">
                              <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.title`)}  placeholder='미션명을 입력해 주세요.'/>
                            </div>
                            <div className="right_line2">
                              {/* <label htmlFor="ms_addDesc">
                              <input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label> */}
                              <FormCKEditor
                                data={item.description}
                                handleChange={(value) => {
                                  setValue(`quiz.${i}.description`, value);
                                }}
                                placeholder='미션설명을 입력해 주세요.'
                              />
                            </div>
                            <div className="right_line3">
                                <label htmlFor="ms_type1_q" className="right_label">Q 질문</label>
                                <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.question`)}  placeholder='객관식 퀴즈명을 입력해 주세요.'/>
                            </div>
                            <div className="right_line4">
                                <label className="right_label">A 정답</label>
                                <div className="ms_type0_a_wrap">
                                    <div className={`type0_a_item ${(item?.multiple[0]?.text||'').length === 0 ? 'type0_a_item_last' : ''}`}>
                                        <label htmlFor="type1_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.multiple.0.answer`)} disabled={(item?.multiple[0]?.text||'').length === 0}/></label>
                                        <strong>1</strong>
                                        <input type="text" className="input_default" id="" {...register(`quiz.${i}.multiple.0.text`,{
                                          onChange: (e)=>{
                                            e.target.value = e.target.value;
                                            setValue(`quiz.${i}.multiple.0.answer`, false)
                                          }})} />
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[1]_next" className="select_default" {...register(`quiz.${i}.multiple.0.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                    <div className={`type0_a_item ${(item?.multiple[1]?.text||'').length === 0 ? 'type0_a_item_last' : ''}`}>
                                        <label htmlFor="type1_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.multiple.1.answer`)} disabled={(item?.multiple[1]?.text||'').length === 0}/></label>
                                        <strong>2</strong>
                                        <input type="text" className="input_default" id="" {...register(`quiz.${i}.multiple.1.text`,{
                                          onChange: (e)=>{
                                            e.target.value = e.target.value;
                                            setValue(`quiz.${i}.multiple.1.answer`, false)
                                          }})} />
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[2]_next" className="select_default" {...register(`quiz.${i}.multiple.1.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                    <div className={`type0_a_item ${(item?.multiple[2]?.text||'').length === 0 ? 'type0_a_item_last' : ''}`}>
                                        <label htmlFor="type0_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.multiple.2.answer`)} disabled={(item?.multiple[2]?.text||'').length === 0}/></label>
                                        <strong>3</strong>
                                        <input type="text" className="input_default" id="" {...register(`quiz.${i}.multiple.2.text`,{
                                          onChange: (e)=>{
                                            e.target.value = e.target.value;
                                            setValue(`quiz.${i}.multiple.2.answer`, false)
                                          }})}/>
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[3]_next" className="select_default" {...register(`quiz.${i}.multiple.2.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                    <div className={`type0_a_item ${(item?.multiple[3]?.text||'').length === 0 ? 'type0_a_item_last' : ''}`}>
                                        <label htmlFor="type0_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.multiple.3.answer`)} disabled={(item?.multiple[3]?.text||'').length === 0}/></label>
                                        <strong>4</strong>
                                        <input type="text" className="input_default" id="" {...register(`quiz.${i}.multiple.3.text`,{
                                          onChange: (e)=>{
                                            e.target.value = e.target.value;
                                            setValue(`quiz.${i}.multiple.3.answer`, false)
                                          }})}/>
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[3]_next" className="select_default" {...register(`quiz.${i}.multiple.3.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                    <div className={`type0_a_item ${(item?.multiple[4]?.text||'').length === 0 ? 'type0_a_item_last' : ''}`}>
                                        <label htmlFor="type0_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.multiple.4.answer`)} disabled={(item?.multiple[4]?.text||'').length === 0}/></label>
                                        <strong>5</strong>
                                        <input type="text" className="input_default" id="" {...register(`quiz.${i}.multiple.4.text`,{
                                          onChange: (e)=>{
                                            e.target.value = e.target.value;
                                            setValue(`quiz.${i}.multiple.4.answer`, false)
                                          }})}/>
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[3]_next" className="select_default" {...register(`quiz.${i}.multiple.4.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                </div>
                            </div>
                            <div className="right_line5">
                              <label className="right_label">힌트</label>
                              <div className="ms_type0_hint">
                                {
                                  Array.from(Array(5).keys()).map(j=><input type="text" className="input_default" placeholder={`미작성 시 노출되지 않음`} {...register(`quiz.${i}.hint.${j}`)} />)
                                }
                              </div>
                            </div>
                        </div>
                    </div>}


                    {item.type === 'ox' && <div className="content_box mission_box mb30">
                        <button type='button' className="btn_close" onClick={()=>removeQuiz(i)}><span className="hide">지우기</span></button>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">A{i+1}</h2>
                            <input type="number" className="input_default" {...register(`quiz.${i}.score`)} placeholder="점수" min={0} onChange={()=>setValue('pass_score', 0)}/>
                        </div>
                        <div className="mission_box__right">
                            <div className="input_address_wrap mb30">
                              <div className="input_postcode">
                                <input type="text" id="acc_add2" className="input_default" {...register(`quiz.${i}.address`)} readOnly/>
                                <button type='button' onClick={()=>setValue(`quiz.${i}.isPostPop`, true)}>주소등록</button>
                                {item.isPostPop && 
                                  <DaumPostcode 
                                      onComplete={e=>setCompleteAddress(i, e.address)}
                                      autoClose={false}
                                      defaultQuery={item?.address}
                                      style={{
                                        position:'absolute',
                                        display:'block',
                                        top:'0',
                                        left:'0',
                                        width:'100%',
                                        height:'50vh',
                                        zIndex: 9,
                                      }}
                                />}
                              </div>
                              {item.coordinate.lat && item.coordinate.lng && <Map
                                center={{
                                  lat: item.coordinate.lat,
                                  lng: item.coordinate.lng,
                                }}
                                style={{
                                  width: "100%",
                                  height: "350px",
                                }}
                                level={3}
                              >
                                <MapMarker
                                  position={{
                                    lat: item.coordinate?.lat,
                                    lng: item.coordinate?.lng,
                                  }}
                                />
                              </Map>}
                            </div>
                            {/* <div className="right_line2">
                            <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label>
                              {item.is_exists_description && <FormCKEditor
                              data={item.description}
                              handleChange={(value) => {
                                setValue(`quiz.${i}.description`, value);
                              }}
                              />}
                          </div> */}
                            <div className="right_line3">
                              <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.title`)}  placeholder='미션명을 입력해 주세요.'/>
                            </div>
                            <div className="right_line2">
                              {/* <label htmlFor="ms_addDesc">
                              <input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label> */}
                              <FormCKEditor
                                data={item.description}
                                handleChange={(value) => {
                                  setValue(`quiz.${i}.description`, value);
                                }}
                                placeholder='미션설명을 입력해 주세요.'
                              />
                            </div>
                            <div className="right_line3">
                                <label htmlFor="ms_type1_q" className="right_label">Q 질문</label>
                                <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.question`)}  placeholder='객관식 퀴즈명을 입력해 주세요.'/>
                            </div>
                            <div className="right_line4">
                                <label className="right_label">A 정답</label>
                                <div className="ms_type0_a_wrap">
                                    <div className={`type0_a_item`}>
                                        <label htmlFor="type1_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.ox.0.answer`)}/></label>
                                        <strong>O</strong>
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[1]_next" className="select_default" {...register(`quiz.${i}.ox.0.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                    <div className={`type0_a_item`}>
                                        <label htmlFor="type1_a[0]"><input type="checkbox" name="" id="" className="chk_default" {...register(`quiz.${i}.ox.1.answer`)}/></label>
                                        <strong>X</strong>
                                        {fields.length - i > 1 && <select name="type1_a[1]_next" id="type1_a[2]_next" className="select_default" {...register(`quiz.${i}.ox.1.forward_index`)}>
                                          <option value=''>다음 문항을 선택해주세요.</option>
                                          {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                        </select>}
                                    </div>
                                </div>
                            </div>
                            <div className="right_line5">
                              <label className="right_label">힌트</label>
                              <div className="ms_type0_hint">
                                {
                                  Array.from(Array(5).keys()).map(j=><input type="text" className="input_default" placeholder={`미작성 시 노출되지 않음`} {...register(`quiz.${i}.hint.${j}`)} />)
                                }
                              </div>
                            </div>
                        </div>
                    </div>}


                    {item.type === 'essay' && 
                    <div className="content_box mission_box mission_box2 mb30">
                    <button type='button' className="btn_close" onClick={()=>removeQuiz(i)}><span className="hide">지우기</span></button>
                    <div className="mission_box__left">
                        <h2 className="colblue mb30">A{i+1}</h2>
                        <input type="number" className="input_default" {...register(`quiz.${i}.score`)} placeholder="점수" min={0} onChange={()=>setValue('pass_score', 0)}/>
                    </div>
                    <div className="mission_box__right">
                    <div className="input_address_wrap mb30">
                              <div className="input_postcode">
                                <input type="text" id="acc_add2" className="input_default" {...register(`quiz.${i}.address`)} readOnly/>
                                <button type='button' onClick={()=>setValue(`quiz.${i}.isPostPop`, true)}>주소등록</button>
                                {item.isPostPop && 
                                  <DaumPostcode 
                                      onComplete={e=>setCompleteAddress(i, e.address)}
                                      autoClose={false}
                                      defaultQuery={item?.address}
                                      style={{
                                        position:'absolute',
                                        display:'block',
                                        top:'0',
                                        left:'0',
                                        width:'100%',
                                        height:'30vh',
                                        zIndex: 9,
                                      }}
                                />}
                              </div>
                              {item.coordinate.lat && item.coordinate.lng && <Map
                                center={{
                                  lat: item.coordinate.lat,
                                  lng: item.coordinate.lng,
                                }}
                                style={{
                                  width: "100%",
                                  height: "350px",
                                }}
                                level={3}
                              >
                                <MapMarker
                                  position={{
                                    lat: item.coordinate?.lat,
                                    lng: item.coordinate?.lng,
                                  }}
                                />
                              </Map>}
                            </div>
                        {/* <div className="right_line2">
                            <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20"  {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label>
                            {item.is_exists_description && <FormCKEditor
                            data={item.description}
                            handleChange={(value) => {
                              setValue(`quiz.${i}.description`, value);
                            }}
                            />}
                        </div> */}
                        <div className="right_line3">
                          <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.title`)}  placeholder='미션명을 입력해 주세요.'/>
                        </div>
                        <div className="right_line2">
                          {/* <label htmlFor="ms_addDesc">
                          <input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label> */}
                          <FormCKEditor
                            data={item.description}
                            handleChange={(value) => {
                              setValue(`quiz.${i}.description`, value);
                            }}
                            placeholder='미션설명을 입력해 주세요.'
                          />
                        </div>
                        {/* <div className="right_line3">
                            <label htmlFor="type_2_ox" className="right_label">답 유무</label>
                            <div>
                                <input type="radio" name="type_2_ox" id="type_2_o" className="chk_circle mr10" /><label htmlFor="type_1_o" className="mr30">정답 있음</label>
                                <input type="radio" name="type_2_ox" id="type_2_x" className="chk_circle mr10" checked /><label htmlFor="type_1_x">정답 없음</label>
                            </div>
                        </div> */}
                        <div className="right_line3">
                            <label htmlFor="ms_type0_q" className="right_label">Q 질문</label>
                            <input type="text" className="input_default" id="ms_type2_q" {...register(`quiz.${i}.question`)}  placeholder='주관식 퀴즈명을 입력해 주세요.'/>
                        </div>
                        <div className="right_line4">
                            <label htmlFor="" className="right_label">A 정답</label>
                            <div className="ms_type1_a_wrap">
                                <input type="text" className="input_default mr20" {...register(`quiz.${i}.essay.answer`)} placeholder='다중정답은 ,로 분리하여 입력합니다. (예 : 정답1,정답2)'/>
                                {fields.length - i > 1 && <select name="type2_a_next" id="type2_a_next" className="select_default" {...register(`quiz.${i}.essay.forward_index`)}>
                                  <option value=''>다음 문항을 선택해주세요.</option>
                                  {
                                            fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                          }
                                </select>}
                            </div>
                        </div>
                        <div className="right_line5">
                          <label className="right_label">힌트</label>
                          <div className="ms_type0_hint">
                            {
                                  Array.from(Array(5).keys()).map(j=><input type="text" className="input_default" placeholder={`미작성 시 노출되지 않음`} {...register(`quiz.${i}.hint.${j}`)} />)
                                }
                          </div>
                        </div>
                    </div>
                </div>
                    }
                 {item.type === 'image' && 
                    <div className="content_box mission_box mission_box3 mb30">
                    <button type='button' className="btn_close" onClick={()=>removeQuiz(i)}><span className="hide">지우기</span></button>
                    <div className="mission_box__left">
                      <h2 className="colblue mb30">A{i+1}</h2>
                      <input type="number" className="input_default" {...register(`quiz.${i}.score`)} placeholder="점수" min={0} onChange={()=>setValue('pass_score', 0)}/>
                    </div>
                    <div className="mission_box__right">
                    <div className="input_address_wrap mb30">
                      <div className="input_postcode">
                        <input type="text" id="acc_add2" className="input_default" {...register(`quiz.${i}.address`)} readOnly/>
                        <button type='button' onClick={()=>setValue(`quiz.${i}.isPostPop`, true)}>주소등록</button>
                        {item.isPostPop && 
                          <DaumPostcode 
                              onComplete={e=>setCompleteAddress(i, e.address)}
                              autoClose={false}
                              defaultQuery={item?.address}
                              style={{
                                position:'absolute',
                                display:'block',
                                top:'0',
                                left:'0',
                                width:'100%',
                                height:'32vh',
                                zIndex: 9,
                              }}
                        />}
                      </div>
                      {item.coordinate.lat && item.coordinate.lng && <Map
                                center={{
                                  lat: item.coordinate.lat,
                                  lng: item.coordinate.lng,
                                }}
                                style={{
                                  width: "100%",
                                  height: "350px",
                                }}
                                level={3}
                              >
                                <MapMarker
                                  position={{
                                    lat: item.coordinate?.lat,
                                    lng: item.coordinate?.lng,
                                  }}
                                />
                              </Map>}                    </div>
                      {/* <div className="right_line2">
                            <label htmlFor="ms_addDesc"><input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20"  {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label>
                            {item.is_exists_description && <FormCKEditor
                            data={item.description}
                            handleChange={(value) => {
                              setValue(`quiz.${i}.description`, value);
                            }}
                            />}
                        </div> */}
                        <div className="right_line3">
                          <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.title`)}  placeholder='미션명을 입력해 주세요.'/>
                        </div>
                        <div className="right_line2">
                          {/* <label htmlFor="ms_addDesc">
                          <input type="checkbox" name="ms_addDesc" id="ms_addDesc" className="chk_default mr20" {...register(`quiz.${i}.is_exists_description`)} value={true} /><strong>미션 설명 추가</strong></label> */}
                          <FormCKEditor
                            data={item.description}
                            handleChange={(value) => {
                              setValue(`quiz.${i}.description`, value);
                            }}
                            placeholder='미션설명을 입력해 주세요.'
                          />
                        </div>
                        <div className="right_line3">
                            <label htmlFor="ms_type3_q" className="right_label">Q 질문</label>
                            <input type="text" className="input_default" id="ms_type3_q" {...register(`quiz.${i}.question`)} placeholder='이미지 퀴즈명을 입력해 주세요.' />
                        </div>
                        {fields.length - i > 1 && <div className="right_line4">
                            <label className="right_label"></label>
                            <div className="ms_type3_a_wrap">
                              <select name="type3_a_next" id="type3_a_next" className="select_default"  {...register(`quiz.${i}.image.forward_index`)}>
                                <option value=''>다음 문항을 선택해주세요.</option>
                                {
                                  fields.slice(i+1).map((r,ri)=><option key={ri} value={r._id}>A{i+ri+2}</option>)
                                }
                              </select>
                            </div>
                        </div>}
                        <div className="right_line5">
                          <label className="right_label">힌트</label>
                          <div className="ms_type0_hint">
                            {
                                  Array.from(Array(5).keys()).map(j=><input type="text" className="input_default" placeholder={`미작성 시 노출되지 않음`} {...register(`quiz.${i}.hint.${j}`)} />)
                                }
                          </div>
                        </div>
                    </div>
                </div>
                    }

                  <div className="btn_add_wrap mb30">
                        <button className="btn_add"><span className="hide">퀴즈 추가</span></button>
                        <div className="btn_add_option">
                            <button type="button" onClick={()=>{
                              insert(i+1,{
                                _id: randomBytes(12).toString('hex'),
                                ...MultipleTemplate
                              })
                            }}>객관식</button>
                            <button type="button" onClick={()=>{
                              insert(i+1,
                                {
                                  _id: randomBytes(12).toString('hex'),
                                  ...EssayTemplate
                                })
                            }}>주관식</button>
                            <button type="button" onClick={()=>{
                              insert(i+1,{
                                _id: randomBytes(12).toString('hex'),
                                ...ImageTemplate})
                            }}>이미지</button>
                            <button type="button" onClick={()=>{
                              insert(i+1,{
                                _id: randomBytes(12).toString('hex'),
                                ...oxTemplate})
                            }}>ox</button>
                        </div>
                    </div>
                        </div>
                      ))
                    }
                    {formData.quiz && formData.quiz.length > 0 && <div className="content_box mb30">
                      <div className="ms_step1_info">
                          <h2 className="mb10"><span style={{color: (formData.pass_score || 0) > 0 ? 'red' : 'black', paddingRight: 0}}>{formData.pass_score || 0}</span>&nbsp;점 이상 미션통과</h2>
                          <div className="content_box mb10">
                              <Slider
                                min={0}
                                max={_.sum(formData.quiz.map(i=>parseInt(i.score || 0)))}
                                value={formData.pass_score}
                                tooltip={false}
                                // onChangeStart={this.handleChangeStart}
                                onChange={v=>setValue('pass_score',v)}
                                // onChangeComplete={this.handleChangeComplete}
                              />
                          </div>
                      </div>
                    </div>}
                    <div className="btns_wrap">
                        <Link to='/solo'><button className="btn_green_line">취소</button></Link>
                        {
                          fields.length > 0 ?
                          <button type="submit" className="btn_green">수정</button>
                          : <button type="button" className="btn_green" style={{background: '#757575'}}>수정</button>
                        }
                    </div>
                </form>
            </div>
        </div>}
        <div id="modalMsshow" className="modal" style={{display: isMissionShow ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <div className="modal_header">
                        <button className="btn_close" onClick={()=>setIsMissionShow(false)}><span className="hide">창 닫기</span></button>
                    </div>
                    <form>
                        <div className="modal_body">
                            <p className="mb30">미션을 바로 노출시키겠습니까?</p>
                            <div className="radio_wrap">
                                <label htmlFor="ms_yes" className="mr30">
                                    <input type="radio" name="ms_show" id="ms_yes" className="chk_circle mr10" value={'true'} {...register(`active`)} checked={formData.active == 'true'}/>노출
                                </label>
                                <label htmlFor="ms_no">
                                    <input type="radio" name="ms_show" id="ms_no" className="chk_circle mr10" value={'false'} {...register(`active`)} checked={formData.active == 'false'}/>숨김
                                </label>
                            </div>
                            {formData.active}
                        </div>
                        <div className="modal_footer">
                            <button className="btn_green">등록 완료</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modalMsCancel" className="modal" style={{display: isMissionCancel ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <div className="modal_header">
                        <button className="btn_close" onClick={()=>setIsMissionCancel(false)}><span className="hide">창 닫기</span></button>
                    </div>
                    <form>
                        <div className="modal_body">
                            <p style={{paddingTop:'30px'}}>퀴즈 등록을 취소하시겠습니까?</p>
                        </div>
                        <div className="modal_footer">
                            <button className="btn_green" onClick={()=>{history.navigate( '/solo'); }}>확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  );
}

export default SoloQuiz;
