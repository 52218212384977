import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import FormCKEditor from '@/components/FormCKEditor';

import * as Yup from 'yup';

import { createGiveaway } from '@actions/giveaway';

import randomBytes from 'randombytes';

import { giveaway } from '@actions/giveaway';
import config from '@/config';

const RewardsEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [isFileUploadSpan, setIsFileUploadSpan] = useState(false);
  const [isRewardsUp, setIsRewardsUp] = useState(false);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    title: Yup.string(),
    description: Yup.string()
  });

  const formOptions = {
    defaultValues: {
      _id: randomBytes(12).toString('hex'),
      title: '',
      description: ''
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  useEffect(() => {
    dispatch(giveaway(params.reward_id))
      .then((res) => {
        console.log(res)
        reset(res)
      })
  }, []);

  const onSubmit = (params) => {
    if(!params.title){
      toast.error('상품명을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    } else if (!params.description) {
      toast.error('상품 설명을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", params.title);

    if(params.image_src[0]){
      formData.append("image", params.image_src[0]);
    }

    formData.append("description", params.description);
    
    dispatch(createGiveaway(formData))
    .then((res) => {
      reset(res)
      toast.success('경품 정보를 수정했습니다..', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
        <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">경품 추가</div>
                <form id="form_setting" onSubmit={handleSubmit(onSubmit)}> 
                    <div className="content_box mb30">
                      <h2 className="colblue">기본 정보</h2>
                      <fieldset className="flex_wrap flex_wrap2">
                          <div className="two-third">
                            <label htmlFor="spots_name" className="required">이름</label>
                            <input type="text" id="spots_name" {...register('title')}  className="input_default" />
                          </div>
                          <div className="two-third">
                            <label htmlFor="ms_name" className="required">대표 이미지</label>
                            <div className="file_upload_wrap" onClick={()=>setIsFileUploadSpan(true)}>
                              <span style={{display: isFileUploadSpan ? 'none' : 'block'}}>jpg, png 파일만 업로드 가능합니다.</span>
                              <label htmlFor="ms_thumb">
                                <input type="file" name="ms_thumb" id="ms_thumb" {...register("image_src")} accept="image/png, image/jpg" />
                              </label>
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                          </div>
                          <div className="two-third">
                              <img src={`${config.apiServerHost}/giveaways/attachment/${formData?.image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/282'}/>
                            </div>
                          <div className="three-third">
                            <label htmlFor="ms_date_from" className="required">미션 설명</label>
                            <FormCKEditor
                              data={formData.description}
                              handleChange={(value) => {
                                setValue('description', value);
                              }}
                            />
                          </div>
                      </fieldset>
                    </div>
                    <div className="btns_wrap">
                      <button type='button' className="btn_green_line"  onClick={()=>{history.navigate('/rewards'); }}>취소</button>
                      <button type='submit' className="btn_green">등록완료</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="modalRewardsUp" className="modal" style={{display: isRewardsUp ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <form>
                        <div className="modal_body">
                            <p style={{paddingTop:'50px'}}>등록이 완료되었습니다.</p>
                        </div>
                        <div className="modal_footer">
                            <button type="submit" className="btn_green" onClick={()=>{ history.navigate('/rewards'); }}>확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  );
}

export default RewardsEdit;
