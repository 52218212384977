import api from '@services/api';
import queryString from 'query-string';

class AccountService {
  login(user_id, password) {
    return api
      .post('/accounts/authenticate', {
        user_id,
        password
      })
  }

  forgotPassword(params) {
    return api.post('/accounts/forgot-password', params)
  }

  signup(params) {
    return api
      .post('/accounts', params)
  }

  createAccount(params) {
    return api
      .post('/accounts/register', params)
  }

  updateAccount(userId, params) {
    return api
      .put(`/accounts/${userId}`, params)
  }

  refreshToken(){
    return api.post('/accounts/refresh-token')
  }

  user(user_id) {
    return api 
      .get(`/accounts/${user_id}`)
  }

  users(params) {
    return api
      .get(`/accounts?${queryString.stringify(params)}`)
  }

  existsUserId(userId) {
    return api
      .get(`/accounts/${userId}/exists`)
  }
}

export default new AccountService();
