import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import moment from 'moment';
import moment from 'moment-timezone';

import Layout from '@screens/Layout';
import Home from '@screens/Home';

import NotFound from '@screens/NotFound';

import PrivateRoute from '@components/PrivateRoute'

import history from '@helpers/history';

import LoginHome from '@screens/Login/Home';

import MemberList from '@screens/Member/List';
import MemberAdd from '@screens/Member/Add';
import MemberEdit from '@screens/Member/Edit';

import SoloList from '@screens/Solo/List';
import SoloAdd from '@screens/Solo/Add';
import SoloQuiz from '@screens/Solo/Quiz';
import SoloEdit from '@screens/Solo/Edit';

import TeamList from '@screens/Team/List';
import TeamAdd from '@screens/Team/Add';
import TeamQuiz from '@screens/Team/Quiz';
import TeamEdit from '@screens/Team/Edit';

import StampList from '@screens/Stamp/List';
import StampAdd from '@screens/Stamp/Add';
import StampEdit from '@screens/Stamp/Edit';
import StampQuiz from '@screens/Stamp/Quiz';

import SpotsList from '@screens/Spots/List';
import SpotsAdd from '@screens/Spots/Add';
import SpotsEdit from '@screens/Spots/Edit';

import NoticeList from '@screens/Notice/List';
import NoticeAdd from '@screens/Notice/Add';
import NoticeEdit from '@screens/Notice/Edit';

import EventList from '@screens/Event/List';
import EventAdd from '@screens/Event/Add';
import EventEdit from '@screens/Event/Edit';

import RewardsList from '@screens/Rewards/List';
import RewardsAdd from '@screens/Rewards/Add';
import RewardsEdit from '@screens/Rewards/Edit';

import TermsHome from '@screens/Terms/Home';

function Router() {
  const currentUser = useSelector(state => state.user);
  const parameters = new URLSearchParams(window.location.search);
  
  let element = useRoutes([
    { path: '/',
      element: <PrivateRoute><Layout /></PrivateRoute>,
      // element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: 'member', element: <MemberList /> },
        { path: 'member/add', element: <MemberAdd /> },
        { path: 'member/:member_id/edit', element: <MemberEdit /> },
        { path: 'solo', element: <SoloList /> },
        { path: 'solo/add', element: <SoloAdd /> },
        { path: 'solo/:mission_id/quiz', element: <SoloQuiz /> },
        { path: 'solo/:mission_id/edit', element: <SoloEdit /> },
        { path: 'team', element: <TeamList /> },
        { path: 'team/add', element: <TeamAdd /> },
        { path: 'team/:mission_id/edit', element: <TeamEdit /> },
        { path: 'team/:mission_id/quiz', element: <TeamQuiz /> },
        { path: 'stamp', element: <StampList /> },
        { path: 'stamp/add', element: <StampAdd /> },
        { path: 'stamp/:mission_id/quiz', element: <StampQuiz /> },
        { path: 'stamp/:mission_id/edit', element: <StampEdit /> },
        { path: 'spots', element: <SpotsList /> },
        { path: 'spots/add', element: <SpotsAdd /> },
        { path: 'spots/:spot_id/edit', element: <SpotsEdit /> },
        { path: 'notice', element: <NoticeList /> },
        { path: 'notice/add', element: <NoticeAdd /> },
        { path: 'notice/:notice_id/edit', element: <NoticeEdit /> },
        { path: 'event', element: <EventList /> },
        { path: 'event/add', element: <EventAdd /> },
        { path: 'event/:event_id/edit', element: <EventEdit /> },
        { path: 'rewards', element: <RewardsList /> },
        { path: 'rewards/add', element: <RewardsAdd /> },
        { path: 'rewards/:reward_id/edit', element: <RewardsEdit /> },
        { path: 'terms', element: <TermsHome /> },
      ]
    },
    { path: 'login', element: <LoginHome /> },
    { path: 'not-found', element: <NotFound /> },
    { path: '*', element: <Navigate to='/' /> }
  ]);

  return element;
}

export default Router;
