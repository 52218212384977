import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import DatePicker from "react-datepicker";
import FormCKEditor from '@/components/FormCKEditor';
import * as Yup from 'yup';
import _ from 'lodash';

import randomBytes from 'randombytes';

import { event, updateEvent, deleteAttachmentFile } from '@actions/board';
import config from '@/config';

const EventAdd = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [isFileUploadSpan, setIsFileUploadSpan] = useState(false);

    const validationSchema = Yup.object().shape({
      _id: Yup.string(),
      type: Yup.string(),
      title: Yup.string().required('제목을 입력해 주세요'),
      body: Yup.string().required('내용을 입력해 주세요'),
      link: Yup.string().url('올바른 웹사이트 주소를 입력해 주세요'),
      event_start_date: Yup.date().required('시작일을 선택해 주세요'),
      event_end_date: Yup.date().required('종료일을 선택해 주세요'),
      is_unlimited_date: Yup.boolean(),
      cover_image_src: Yup.mixed().required('대표이미지를 선택해 주세요.'),
      attachment1_src: Yup.mixed(),
      attachment2_src: Yup.mixed(),
      attachment3_src: Yup.mixed(),
      attachment4_src: Yup.mixed(),
      attachment5_src: Yup.mixed(),
      is_notice: Yup.boolean()
    });
  
    const formOptions = {
      defaultValues: {
        _id: randomBytes(12).toString('hex'),
        title: '',
        body: '',
        link: '',
        event_start_date: new Date(),
        event_end_date: new Date(),
        is_unlimited_date: false,
        cover_image: '',
        attachment1: '',
        attachment2: '',
        attachment3: '',
        attachment4: '',
        attachment5: '',
        is_notice: false,
      },
      resolver: yupResolver(validationSchema)
    };
  
    const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
  
    const formData = watch();
  
    console.log('formData', formData)
  
    console.log(errors)
    
    useEffect(() => {
        dispatch(event(params.event_id))
          .then((res) => {
            console.log('res>>', res)
            reset(res);
          })
    }, []);    
  
    const onSubmit = (data) => {
       const formData = new FormData();
      formData.append('type', 'event');
      formData.append("title", data.title);

      if(data.cover_image_src[0]){
        formData.append("cover_image", data.cover_image_src[0]);
      }
  
      if(data.attachment1_src[0]){
        formData.append("attachment1", data.attachment1_src[0]);
      }
  
      if(data.attachment2_src[0]){
        formData.append("attachment2", data.attachment2_src[0]);
      }
  
      if(data.attachment3_src[0]){
        formData.append("attachment3", data.attachment3_src[0]);
      }
  
      if(data.attachment4_src[0]){
        formData.append("attachment4", data.attachment4_src[0]);
      }
  
      if(data.attachment5_src[0]){
        formData.append("attachment5", data.attachment5_src[0]);
      }
      
      formData.append("body", data.body);
      formData.append("link", data.link);
      formData.append("event_start_date", data.event_start_date);
      formData.append("event_end_date", data.event_end_date);
      formData.append("is_unlimited_date", data.is_unlimited_date);
      formData.append("is_notice", data.is_notice);
      
      dispatch(updateEvent(params.event_id, formData))
      .then((res) => {
        //   history.navigate(`/event`, {replace: true})
        reset(res)
        toast.success('이벤트를 수정했습니다..', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      })
      .catch(message => {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      });
    }

  return (
    <>
    <div className="container_adm">
        <div className="container_adm_inner">
            <div className="adm_title_sm mb30">이벤트 추가</div>
            <form id="form_setting" onSubmit={handleSubmit(onSubmit)}>  
                <div className="content_box mb30">
                    <h2 className="colblue">기본 정보</h2>
                    <fieldset className="flex_wrap flex_wrap2">
                        <div className="two-third">
                            <label htmlFor="spots_name" className="required">제목</label>
                            <div className='input_wrap'>
                                <input type="text" id="spots_name" className="input_default" {...register("title")}/>
                                {errors && errors?.title?.message && <p className="input_warn">
                                <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.title?.message}
                                </p>}
                            </div>
                        </div>
                        <div className="two-third">
                            <label htmlFor="ms_date_from" className="required">이벤트 기간</label>
                            <div className="ms_datepicker">
                                <label htmlFor="ms_date_from">
                                <DatePicker
                                    selected={new Date(formData.event_start_date)}
                                    onChange={(date) => setValue('event_start_date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    selectsStart
                                    startDate={new Date(formData.event_start_date)}
                                    endDate={new Date(formData.event_end_date)}
                                />
                                {errors && errors?.event_start_date?.message && <p className="input_warn">
                                <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.event_start_date?.message}
                                </p>}
                                </label>
                                <span>~</span>
                                <label htmlFor="ms_date_to" className="mr10">
                                <DatePicker
                                    selected={new Date(formData.event_end_date)}
                                    onChange={(date) => setValue('event_end_date', date)}
                                    dateFormat="yyyy-MM-dd"
                                    selectsEnd
                                    startDate={new Date(formData.event_start_date)}
                                    endDate={new Date(formData.event_end_date)}
                                    minDate={new Date(formData.event_start_date)}
                                />
                                {errors && errors?.event_end_date?.message && <p className="input_warn">
                                <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.event_end_date?.message}
                                </p>}
                                </label>
                                <input type="checkbox" name="ms_date_nolimit" id="ms_date_nolimit" {...register("is_unlimited_date")} className="chk_default" value={true} />
                                <label htmlFor="ms_date_nolimit">기간제한 없음</label>
                            </div>
                        </div>
                        <div className="two-third">
                            <label htmlFor="ms_name" className="required">대표 이미지</label>
                            <div className='input_wrap'>
                                <div className="file_upload_wrap" onClick={()=>setIsFileUploadSpan(true)}>
                                    <span style={{display: isFileUploadSpan ? 'none' : 'block'}}>jpg, png 파일만 업로드 가능합니다.</span>
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("cover_image_src")}  accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {errors && errors?.cover_image?.message && <p className="input_warn">
                                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.cover_image?.message}
                                  </p>}
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                        </div>
                        <div className="two-third">
                              <img src={`${config.apiServerHost}/boards/attachment/${formData?.cover_image?.server_filename}`} alt="" width={'100%'} />
                            </div>
                        <div className="three-third">
                            <label htmlFor="ms_date_from" className="required">내용</label>
                            <div className='input_wrap'>
                                  <FormCKEditor
                                      data={formData.body}
                                      handleChange={(value) => {
                                          setValue('body', value);
                                      }}
                                  />
                                  {errors && errors?.body?.message && <p className="input_warn">
                                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.body?.message}
                                  </p>}
                                </div>
                        </div>
                        <div className="two-third">
                            <label htmlFor="ms_name">첨부파일1</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment1_src")}/>
                                </label>
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                        </div>
                        {formData.attachment1&&<div>
                            <Link to={`${config.apiServerHost}/boards/attachment/${formData.attachment1.server_filename}`} target="_blank" download>{formData.attachment1.original_filename}</Link>
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">첨부파일2</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment2_src")}/>
                                </label>
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                        </div>
                        {formData.attachment2&&<div>
                            <Link to={`${config.apiServerHost}/boards/attachment/${formData.attachment2.server_filename}`} target="_blank" download>{formData.attachment2.original_filename}</Link>
                        </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">첨부파일3</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment3_src")}/>
                                </label>
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                        </div>
                        {formData.attachment3&&<div>
                              <Link to={`${config.apiServerHost}/boards/attachment/${formData.attachment3.server_filename}`} target="_blank" download>{formData.attachment3.original_filename}</Link>
                            </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">첨부파일4</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment4_src")}/>
                                </label>
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                        </div>
                        {formData.attachment4&&<div>
                              <Link to={`${config.apiServerHost}/boards/attachment/${formData.attachment4.server_filename}`} target="_blank" download>{formData.attachment4.original_filename}</Link>
                            </div>}
                        <div className="two-third">
                            <label htmlFor="ms_name">첨부파일5</label>
                            <div className="file_upload_wrap">
                                <label htmlFor="ms_thumb">
                                    <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment5_src")}/>
                                </label>
                            </div>
                            {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                        </div>
                        {formData.attachment5&&<div>
                              <Link to={`${config.apiServerHost}/boards/attachment/${formData.attachment5.server_filename}`} target="_blank" download>{formData.attachment5.original_filename}</Link>
                            </div>}
                        <div className="two-third">
                            <label htmlFor="">링크</label>
                            <div className='input_wrap'>
                                <input type="text" className="input_default" placeholder="URL을 입력해 주세요" {...register("link")}/>
                                {errors && errors?.link?.message && <p className="input_warn">
                                <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.link?.message}
                                </p>}
                            </div>
                        </div>
                        <div className="one-third">
                            <label htmlFor="notice_if">공지</label>
                            <input type="checkbox" name="notice_if" id="notice_if" className="chk_default" value={true} {...register("is_notice")}/>
                        </div>
                    </fieldset>
                </div>
                <div className="btns_wrap">
                    <button className="btn_green_line" onClick={()=>{history.navigate('/event'); }}>취소</button>
                    <button type="submit" className="btn_green">등록완료</button>
                </div>
            </form>
        </div>
    </div>
    {/* <div id="modalEventUp" className="modal" style={{display: isEventUp ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <form>
                        <div className="modal_body">
                            <p style={{paddingTop:'50px'}}>등록이 완료되었습니다.</p>
                        </div>
                        <div className="modal_footer">
                            <button type="submit" className="btn_green" onClick={()=>{history.navigate(basePath ? `/${basePath}/event` : '/event'); }}>확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div> */}
    </>
  );
}

export default EventAdd;
