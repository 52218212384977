import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import moment from 'moment'
import Moment from 'react-moment';

import * as Yup from 'yup';

import { user, updateAccount } from '@actions/account';

const MemberEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [myPageData, setMyPageData] = useState(null);
  const [isDupMobile, setIsDupMobile] = useState(false);
  
  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
              .oneOf([Yup.ref('password')], 'Passwords must match'),
    nickname: Yup.string().required('Required field'),
    mobile: Yup.string().required('Required field'),
    organization: Yup.string(),
    memo: Yup.string(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  useEffect(() => {
    dispatch(user(params.member_id))
      .then((res) => {
        setMyPageData(res);
        reset(res)
      })
  }, []);


  const onSubmit = (params) => {
    if(params.password){//패스워드 입력한 경우
      if(params.password.length < 6){
        // setError('password', { message: 'Password must be at least 6 characters' });
        toast.error('패스워드를 최소 6자리 이상입력해 주세요.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        return;
      } else if (!params.confirmPassword) {
        toast.error('패스워드 확인을 입력해 주세요.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        return;
      } else if (params.password !== params.confirmPassword) {
        toast.error('입력한 패스워드를 다시 확인해 주세요.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        return;
      }
    }
    
    // if(!isCheckMobile && (myPageData.countryCode !== params.countryCode || myPageData.mobile !== params.mobile)){//휴대폰 번호입력이 된겨우
    //   setError('isCheckMobile', { message: 'Please check mobile' });
    //   return;
    // }
    dispatch(updateAccount(myPageData.id, {
      nickname: params.nickname,
      password: params.password,
      confirmPassword: params.confirmPassword,
      organization: params.organization,
      verified: params.verified,
      mobile: params.mobile,
      memo: params.memo,
    }))
    .then((res) => {
      history.navigate(`/member`, { replace: true })
    toast.success('회원정보를 수정했습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
    });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
        {myPageData && <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title mb30">회원정보 수정</div>
                <form id="form_memberAdd" onSubmit={handleSubmit(onSubmit)}> 
                    <div className="content_box mb30">
                        <h2 className="colblue">회원 정보</h2>
                        <fieldset className="flex_wrap">
                            <div className="one-third">
                                <label htmlFor="memb_joinDate">회원 가입일</label>
                                <input type="text" id="memb_joinDate" className="input_default" value={moment(myPageData.created).format('YYYY.MM.DD')} disabled />
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_leaveDate">회원 탈퇴일</label>
                                <input type="text" id="memb_leaveDate" className="input_default" value={myPageData.leaved ? moment(myPageData.leaved_date).format('YYYY.MM.DD') : ''} disabled />
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_verify">본인 확인</label>
                                <select name="memb_verify" id="memb_verify" {...register('verified')} className="select_default">
                                    <option value={false}>N</option>
                                    <option value={true}>Y</option>
                                </select>
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_name">닉네임</label>
                                <input type="text" id="memb_name" {...register('nickname')} className="input_default" placeholder="닉네임을 입력해 주세요"/>
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_id">아이디</label>
                                <input type="text" id="memb_id" {...register('user_id')}  className="input_default" placeholder="?" disabled/>
                            </div>
                            <div className="one-third">
                                <label htmlFor="memeb_mobile">휴대폰번호</label>
                                <input type="text" id="memeb_mobile" {...register('mobile')}  className="input_default" placeholder="휴대폰번호를 입력해 주세요" />
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_group">소속</label>
                                <input type="text" id="memb_group" {...register('organization')}  className="input_default" placeholder="소속을 입력해 주세요." />
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_pw">패스워드</label>
                                <input type="password" id="memb_pw" {...register('password')}  className="input_default"  />
                            </div>
                            <div className="one-third">
                                <label htmlFor="memb_pw_chk">패스워드 확인</label>
                                <input type="password" id="memb_pw_chk" {...register('confirmPassword')}  className="input_default"  />
                            </div>
                        </fieldset>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">참여 이력 <span className="memb_tit_extra">(3회 2승)</span></h2>
                        <div className="memb_history_wrap">
                            <ul>
                                <li>
                                    <div className="memb_history__left">
                                        <span>22.05.26 17.25</span>
                                        <span>광주 무등산 서석대로 가서 황금열쇠를 찾아라!</span>
                                    </div>
                                    <div className="memb_history__right">
                                        <span className="memb_history_result">성공</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="memb_history__left">
                                        <span>22.05.26 17.25</span>
                                        <span>광주 무등산의 비밀장소를 찾아라!</span>
                                    </div>
                                    <div className="memb_history__right">
                                        <span className="memb_history_result" data-cate="fail">실패</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">관리자 메모</h2>
                        <label htmlFor="manager_memo">
                            <textarea name="manager_memo" id="manager_memo" {...register('memo')} rows="7" className="textarea_default"></textarea>
                        </label>
                    </div>
                    <div className="btns_wrap">
                        <button type='button' className="btn_blue_line" onClick={()=>history.navigate('/member')}>취소</button>
                        {/* <button className="btn_green">저장</button> */}
                        <button type='submit' className='btn_blue_line' disabled={isSubmitting}><span className='spsans'>저장</span></button>
                    </div>
                </form>
            </div>
        </div>}
    </>
  );
}

export default MemberEdit;
