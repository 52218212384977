import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import FormCKEditor from '@/components/FormCKEditor';
import * as Yup from 'yup';

import { createSpot } from '@actions/spot';
import DaumPostcode from 'react-daum-postcode';
import {Map, MapMarker} from 'react-kakao-maps-sdk';

const SpotsAdd = () => {
  const dispatch = useDispatch();
  const [isFileUploadSpan, setIsFileUploadSpan] = useState(false);
  const [isSpotsShow, setIsSpotsShow] = useState(false);
  const { kakao } = window

  const validationSchema = Yup.object().shape({
    type: Yup.string(),
    title: Yup.string(),
    description: Yup.string(),
    address: Yup.string(),
    coordinate: Yup.mixed(),
  });

  const formOptions = {
    defaultValues: {
      type:'tour',
      title: '',
      description: '',
      address: '',
      coordinate: {},
      isPostPop: false
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  const onSubmit = (data) => {
    if(!data.title){
      toast.error('관광지명을 입력해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } else if (!data.address) {
      toast.error('주소를 입력해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } else if (!data.intro_image[0]) {
      toast.error('대표 이미지를 선택해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    }

    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("title", data.title);

    if(data.audio[0]){
      formData.append("audio", data.audio[0]);
    }

    formData.append("intro_image", data.intro_image[0]);

    if(data.additional_image1[0]){
      formData.append("additional_image1", data.additional_image1[0]);
    }

    if(data.additional_image2[0]){
        formData.append("additional_image2", data.additional_image2[0]);
    }

    if(data.additional_image4[0]){
        formData.append("additional_image4", data.additional_image4[0]);
    }

    if(data.additional_image5[0]){
        formData.append("additional_image5", data.additional_image5[0]);
    }

    if(data.additional_image6[0]){
      formData.append("additional_image6", data.additional_image6[0]);
    }

    if(data.additional_image7[0]){
      formData.append("additional_image7", data.additional_image7[0]);
    }

    if(data.additional_image8[0]){
      formData.append("additional_image8", data.additional_image8[0]);
    }

    if(data.additional_image9[0]){
      formData.append("additional_image9", data.additional_image9[0]);
    }

    if(data.additional_image10[0]){
      formData.append("additional_image10", data.additional_image10[0]);
    }

    formData.append("address", data.address);
    formData.append("coordinate", new Blob([JSON.stringify(data.coordinate)], {
      type: "application/json"
    }),'');
    formData.append("description", data.description);
    
    dispatch(createSpot(formData))
    .then((res) => {
      history.navigate(`/spots`, { replace: true })
      toast.success('관광지가 추가되었습니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const setCompleteAddress = (address) => {
    setValue(`address`, address)

    const geocoder = new kakao.maps.services.Geocoder();

    geocoder.addressSearch(address, (result, status) => {
      if (status == kakao.maps.services.Status.OK) {
        setValue(`coordinate`, {
          lat: result[0].y,
          lng: result[0].x,
        })
        setValue(`isPostPop`, false)
      } else {
        setValue(`address`, '')
      }
    });    
  }


  return (
    <>
        <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">관광지 추가</div>
                <form id="form_setting" onSubmit={handleSubmit(onSubmit)}>
                    <div className="content_box mb30">
                        <h2 className="colblue">기본 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                          <div className="two-third">
                              <label htmlFor="spots_name" className="required">이름</label>
                              <input type="text" id="spots_name" className="input_default"  {...register(`title`)} />
                          </div>
                          <div className="two-third">
                            <label htmlFor="stamp_location" className="required">주소</label>
                            <div className="spots_address_wrap" style={{position:'relative'}}>
                            <input type="text" id="acc_add2" className="input_default" {...register(`address`)} readOnly/>
                                <button type='button' className="btn_search_lg mr10" onClick={()=>setValue(`isPostPop`, true)}><span className="hide">주소 검색</span></button>
                                {formData.isPostPop && 
                                  <DaumPostcode 
                                      onComplete={e=>setCompleteAddress(e.address)}
                                      autoClose={false}
                                      defaultQuery={formData?.address}
                                      style={{
                                        position:'absolute',
                                        display:'block',
                                        top:'0',
                                        left:'0',
                                        width:'100%',
                                        height:'50vh',
                                        zIndex: 9,
                                      }}
                                />}
                            </div>
                          </div>
                          <div >
                              {formData.coordinate?.lat && formData.coordinate?.lng && <Map
                                center={{
                                  lat: formData.coordinate.lat,
                                  lng: formData.coordinate.lng,
                                }}
                                style={{
                                  width: "100%",
                                  height: "350px",
                                }}
                                level={3}
                              >
                                <MapMarker
                                  position={{
                                    lat: formData.coordinate?.lat,
                                    lng: formData.coordinate?.lng,
                                  }}
                                />
                              </Map>}
                          </div>
                          <div className="one-third">
                            <label htmlFor="spots_purpose" className="required">목적</label>
                            <select name="spots_purpose" id="spots_purpose" className="select_default" {...register(`type`)}>
                              <option value="tour">관광</option>
                              <option value="activity">체험</option>
                            </select>
                          </div>
                        </fieldset>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">관광지 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="two-third">
                                <label htmlFor="ms_name" className="required">오디오(mp3)</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("audio")}  accept="audio/mpeg, audio/mp3" />
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name" className="required">대표 이미지</label>
                                <div className="file_upload_wrap" onClick={()=>setIsFileUploadSpan(true)}>
                                    <span style={{display: isFileUploadSpan ? 'none' : 'block'}}>jpg, png 파일만 업로드 가능합니다.</span>
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("intro_image")}  accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지1</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image1")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지2</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image2")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지3</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image3")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지4</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image4")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지5</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image5")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지6</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image6")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지7</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image7")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지8</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image8")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지9</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image9")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지10</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image10")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="three-third">
                                <label htmlFor="ms_date_from" className="required">관광지 소개</label>
                                <FormCKEditor
                                    data={formData.description}
                                    handleChange={(value) => {
                                        setValue('description', value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                    <div className="btns_wrap">
                        <button className="btn_green_line" onClick={()=>{history.navigate('/spots'); }}>취소</button>
                        <button type="submit" className="btn_green">등록</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="modalSpotsShow" className="modal" style={{display: isSpotsShow ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <div className="modal_header">
                        <button className="btn_close" onClick={()=>setIsSpotsShow(false)}><span className="hide">창 닫기</span></button>
                    </div>
                    <form>
                        <div className="modal_body">
                            <p className="mb30">광주 관광지를 바로 노출시키겠습니까?</p>
                            <div className="radio_wrap">
                                <label htmlFor="ms_yes" className="mr30">
                                    <input type="radio" name="ms_show" id="ms_yes" className="chk_circle mr10" checked />노출
                                </label>
                                <label htmlFor="ms_no">
                                    <input type="radio" name="ms_show" id="ms_no" className="chk_circle mr10" />숨김
                                </label>
                            </div>
                        </div>
                        <div className="modal_footer">
                            <button type="submit" className="btn_green">등록 완료</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  );
}

export default SpotsAdd;
