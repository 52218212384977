import api from '@/services/api';
import queryString from 'query-string';

class BoardService {
  createNotice(params) {
    return api.post('/boards/notice', params)
  }

  updateNotice(notice_id, params) {
    return api.put(`/boards/notice/${notice_id}`, params)
  }

  deleteNotice(notice_id) {
    return api.delete(`/boards/notice/${notice_id}`)
  }

  notices(params) {
    return api.get(`/boards/notice?${queryString.stringify(params)}`)
  }

  notice(notice_id) {
    return api.get(`/boards/notice/${notice_id}`)
  }

  createEvent(params) {
    return api.post('/boards/event', params)
  }

  updateEvent(event_id, params) {
    return api.put(`/boards/event/${event_id}`, params)
  }

  deleteEvent(event_id) {
    return api.delete(`/boards/event/${event_id}`)
  }

  events(params) {
    return api.get(`/boards/event?${queryString.stringify(params)}`)
  }

  event(event_id) {
    return api.get(`/boards/event/${event_id}`)
  }

  createQuestion(params) {
    return api.post('/boards/question', params)
  }

  updateQuestion(question_id, params) {
    return api.put(`/boards/question/${question_id}`, params)
  }

  deleteQuestion(question_id) {
    return api.delete(`/boards/question/${question_id}`)
  }

  questions(params) {
    return api.get(`/boards/question?${queryString.stringify(params)}`)
  }

  question(question_id) {
    return api.get(`/boards/question/${question_id}`)
  }

  createAnswer(question_id, params) {
    console.log(question_id, params)
    return api.post(`/boards/question/${question_id}/answer`, params)
  }

  updateAnswer(question_id, answer_id, params) {
    return api.put(`/boards/question/${question_id}/answer/${answer_id}`, params)
  }

  deleteAnswer(question_id, answer_id) {
    return api.delete(`/boards/question/${question_id}/answer/${answer_id}`)
  }

  activeBoard(params) {
    return api
      .put(`/boards/active`,params)
  }

  inactiveBoard(params) {
    return api
      .put(`/boards/inactive`,params)
  }

  deleteBoard(params) {
    return api
      .put(`/boards/delete`,params)
  }

  deleteAttachmentFile(board_id, idx) {
    return api.delete(`/boards/${board_id}/attachment-file/${idx}`)
  }
}

export default new BoardService();
