import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';

import * as Yup from 'yup';

import { user, createAccount } from '@actions/account';

const MemberAdd = () => {
  const dispatch = useDispatch();

  const { basePath } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
              .oneOf([Yup.ref('password')], 'Passwords must match'),
    nickname: Yup.string().required('Required field'),
    mobile: Yup.string().required('Required field'),
    organization: Yup.string(),
    memo: Yup.string(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const formData = watch();

  const onSubmit = (params) => {
    if(params.password){//패스워드 입력한 경우
      if(params.password.length < 6){
        // setError('password', { message: 'Password must be at least 6 characters' });
        toast.error('패스워드를 최소 6자리 이상입력해 주세요.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        return;
      } else if (!params.confirmPassword) {
        toast.error('패스워드 확인을 입력해 주세요.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        return;
      } else if (params.password !== params.confirmPassword) {
        toast.error('입력한 패스워드를 다시 확인해 주세요.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        return;
      }
    }
    
    // if(!isCheckMobile && (myPageData.countryCode !== params.countryCode || myPageData.mobile !== params.mobile)){//휴대폰 번호입력이 된겨우
    //   setError('isCheckMobile', { message: 'Please check mobile' });
    //   return;
    // }
    dispatch(createAccount(params))
    .then((res) => {
        history.navigate(`/member`, { replace: true })
        toast.success('회원추가 했습니다..', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  
  return (
    <>
    <div className="container_adm">
        <div className="container_adm_inner">
            <div className="adm_title mb30">신규 회원 등록</div>
            <form id="form_memberAdd">
                <div className="content_box mb30">
                    <h2 className="colblue">회원 정보</h2>
                    <fieldset className="flex_wrap">
                        <div className="one-third">
                            <label htmlFor="memb_verify">본인 확인</label>
                            <select name="memb_verify" id="memb_verify" className="select_default">
                                <option value="0">N</option>
                                <option value="1">Y</option>
                            </select>
                        </div>
                        <div className="one-third"></div>
                        <div className="one-third"></div>
                        <div className="one-third">
                            <label htmlFor="memb_name">이름</label>
                            <input type="text" id="memb_name" className="input_default" value="김강연" />
                        </div>
                        <div className="one-third">
                            <label htmlFor="memb_id">아이디</label>
                            <input type="text" id="memb_id" className="input_default" value="?" />
                        </div>
                        <div className="one-third">
                            <label htmlFor="memeb_mobile">휴대폰번호</label>
                            <input type="text" id="memeb_mobile" className="input_default" value="01012345678" />
                        </div>
                        <div className="one-third">
                            <label htmlFor="memb_group">소속</label>
                            <input type="text" id="memb_group" className="input_default" value="학동초등학교" />
                        </div>
                        <div className="one-third">
                            <label htmlFor="memb_pw">패스워드</label>
                            <input type="text" id="memb_pw" className="input_default" value="" />
                        </div>
                        <div className="one-third">
                            <label htmlFor="memb_pw_chk">패스워드 확인</label>
                            <input type="text" id="memb_pw_chk" className="input_default" value="" />
                        </div>
                    </fieldset>
                </div>
                <div className="content_box mb30">
                    <h2 className="colblue">관리자 메모</h2>
                    <label htmlFor="manager_memo">
                        <textarea name="manager_memo" id="manager_memo" rows="7" className="textarea_default"></textarea>
                    </label>
                </div>
                <div className="btns_wrap">
                    <button className="btn_blue_line" onClick={()=>{history.navigate(basePath ? `/${basePath}/member` : '/member'); }}>취소</button>
                    <button className="btn_green">저장</button>
                </div>
            </form>
        </div>
    </div>
    </>
  );
}

export default MemberAdd;
