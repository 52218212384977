import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormCKEditor from '@/components/FormCKEditor';

import * as Yup from 'yup';
import randomBytes from 'randombytes';

import { createMission } from '@actions/mission';
import { giveaways } from '@actions/giveaway';
import config from '@/config'
import moment from 'moment'

const StampAdd = () => {
  const dispatch = useDispatch();
  const [isFileUploadSpan, setIsFileUploadSpan] = useState(false);
  const [giveawayList, setGiveawayList] = useState(null);

  const validationSchema = Yup.object().shape({
      _id: Yup.string(),
      type: Yup.string(),
      title: Yup.string(),
      from_date: Yup.string(),
      to_date: Yup.string(),
      is_unlimit_date: Yup.boolean(),
      limit_time: Yup.number(),
      max_person: Yup.number().nullable(true),
      description: Yup.string(),
      giveaway: Yup.array(),
      giveaway_description: Yup.string()
  });

  const formOptions = {
      defaultValues: {
      _id: randomBytes(12).toString('hex'),
      title: '',
      description: '',
      giveaway: [],
      giveaway_description: '',
      },
      resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  console.log('formData', formData)

  useEffect(() => {
    dispatch(giveaways({
        page: 1,
        limit: 100000
    }))
    .then((res) => {
        setGiveawayList(res.docs);
    })
  }, []);


  const onSubmit = (params) => {
      if(!params.title){
      toast.error('제목을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
      } else if (!params.intro_image[0]) {
      toast.error('대표 이미지를 선택해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
      } else if (!params.from_date) {
      toast.error('미션 시작일을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
      } else if (!params.to_date) {
      toast.error('미션 종료일을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
      } else if (!params.limit_time) {
      toast.error('미션 시간을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
      }

      const formData = new FormData();
      formData.append('type', 'stamp');
      formData.append("title", params.title);
      formData.append("intro_image", params.intro_image[0]);

      formData.append("from_date", `${moment(params.from_date).format("YYYY-MM-DD")} ${params.from_date_time ? params.from_date_time : '00:00:00'}`);
      formData.append("to_date", `${moment(params.to_date).format("YYYY-MM-DD")} ${params.to_date_time ? params.to_date_time : '23:59:59'}`);

      if(params.is_unlimit_date){
      formData.append("is_unlimit_date", params.is_unlimit_date);
      }

      if(params.limit_time){
      formData.append("limit_time", params.limit_time);
      }

      if(params.max_person){
      formData.append("max_person", params.max_person);
      }  

      if(!params.max_person){
      formData.append("is_max_person", true);
      }  

      if(params.additional_image1[0]){
      formData.append("additional_image1", params.additional_image1[0]);
      }

      if(params.additional_image2[0]){
          formData.append("additional_image2", params.additional_image2[0]);
      }

      if(params.additional_image4[0]){
          formData.append("additional_image4", params.additional_image4[0]);
      }

      if(params.additional_image5[0]){
          formData.append("additional_image5", params.additional_image5[0]);
      }

      if(params.additional_image6[0]){
      formData.append("additional_image6", params.additional_image6[0]);
      }

      if(params.additional_image7[0]){
      formData.append("additional_image7", params.additional_image7[0]);
      }

      if(params.additional_image8[0]){
      formData.append("additional_image8", params.additional_image8[0]);
      }

      if(params.additional_image9[0]){
      formData.append("additional_image9", params.additional_image9[0]);
      }

      if(params.additional_image10[0]){
      formData.append("additional_image10", params.additional_image10[0]);
      }

      formData.append("description", params.description);
      formData.append("giveaway", params.giveaway);
      formData.append("giveaway_description", params.giveaway_description);
      
      dispatch(createMission(formData))
      .then((res) => {
          history.navigate(`/stamp/${res.id}/quiz`, {replace: true})
      })
      .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      });
  }

  return (
    <>
        <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">미션 추가</div>
                <form id="form_missionAdd" onSubmit={handleSubmit(onSubmit)}> 
                    <div className="content_box mb30">
                        <h2 className="colblue">기본 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="two-third">
                                <label htmlFor="ms_name" className="required">제목</label>
                                <input type="text" id="ms_name" {...register('title')} className="input_default" />
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_date_from" className="required">미션 기간</label>
                                <div className="ms_datepicker">
                                <label htmlFor="ms_date_from">
                                    <DatePicker
                                        selected={formData.from_date}
                                        onChange={(date) => setValue('from_date', date)}
                                        dateFormat="yyyy-MM-dd"
                                        selectsStart
                                        startDate={formData.from_date}
                                        endDate={formData.to_date}
                                    />
                                    <input type='time' {...register('from_date_time')} className="input_default" />
                                    </label>
                                    <span>~</span>
                                    <label htmlFor="ms_date_to" className="mr10">
                                    <DatePicker
                                        selected={formData.to_date}
                                        onChange={(date) => setValue('to_date', date)}
                                        dateFormat="yyyy-MM-dd"
                                        selectsEnd
                                        startDate={formData.from_date}
                                        endDate={formData.to_date}
                                        minDate={formData.from_date}
                                    />
                                    <input type='time' {...register('to_date_time')} className="input_default" />
                                    </label>
                                    <input type="checkbox" name="ms_date_nolimit" id="ms_date_nolimit" {...register("is_unlimit_date")} className="chk_default" value={true} />
                                    <label htmlFor="ms_date_nolimit">기간제한 없음</label>
                                </div>
                            </div>
                            <div className="one-third">
                                <label htmlFor="ms_duration" className="required">미션 시간</label>
                                <input type="number" id="ms_duration" className="input_default" {...register("limit_time")} min={1} placeholder="시간 기준 (ex. 5)" />
                            </div>
                            <div className="one-third">
                                <label htmlFor="ms_max">최대 인원</label>
                                <input type="number" id="ms_max" className="input_default" {...register("max_person")} placeholder="미입력 시 N명으로 설정됩니다" />
                            </div>
                        </fieldset>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">미션 상세 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                        <div className="two-third">
                                <label htmlFor="ms_name" className="required">대표 이미지</label>
                                <div className="file_upload_wrap" onClick={()=>setIsFileUploadSpan(true)}>
                                    <span style={{display: isFileUploadSpan ? 'none' : 'block'}}>jpg, png 파일만 업로드 가능합니다.</span>
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("intro_image")}  accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지1</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image1")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지2</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image2")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지3</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image3")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지4</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image4")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지5</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image5")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지6</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image6")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지7</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image7")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지8</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image8")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지9</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image9")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지10</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image10")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            <div className="three-third">
                                <label htmlFor="ms_date_from" className="required">미션 설명</label>
                                <FormCKEditor
                                    data={formData.description}
                                    handleChange={(value) => {
                                        setValue('description', value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">경품 상세 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="three-third">
                                <div className="rewards_list_wrap mb30">
                                    {
                                        giveawayList && giveawayList.map((item, ri)=><div className="reward_item">
                                        <label htmlFor={`reward_item_${item.id}`}>
                                            <input type="checkbox" name={`reward_item_${item.id}`} id={`reward_item_${item.id}`} className="chk_default" {...register("giveaway")} value={item.id}/>
                                            <img src={`${config.apiServerHost}/giveaways/attachment/${item?.image?.server_filename}`} alt="" />
                                        </label>
                                        <p>{item.title}</p>
                                    </div>)
                                    }
                                </div>
                            </div>
                            <div className="three-third">
                                <label htmlFor="ms_date_from">경품 안내 설명</label>
                                <FormCKEditor
                                    data={formData.giveaway_description}
                                    handleChange={(value) => {
                                        setValue('giveaway_description', value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                    <div className="btns_wrap">
                        <button type="button" className="btn_green_line" onClick={()=>{history.navigate('/stamp', {replace: true}); }}>취소</button>
                        <button type="submit" className="btn_green">다음</button>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
}

export default StampAdd;
