
import giveawayService from '@/services/giveaway.service';

export const createGiveaway = (params) => (dispatch) => {
  return giveawayService.createGiveaway(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateGiveaway = (giveaway_id, params) => (dispatch) => {
  return giveawayService.updateGiveaway(giveaway_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteGiveaway = (giveaway_id) => (dispatch) => {
  return giveawayService.deleteGiveaway(giveaway_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const giveaways = (params) => (dispatch) => {
  return giveawayService.giveaways(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const giveaway = (giveaway_id) => (dispatch) => {
  return giveawayService.giveaway(giveaway_id)
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};