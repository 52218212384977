import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import { site, updateSite } from '@actions/site';

import * as Yup from 'yup';

export default function Home() {
    const dispatch = useDispatch();
    
    const validationSchema = Yup.object().shape({
        owner_name: Yup.string(),
        mobile: Yup.string(),
        tel: Yup.string(),
        email: Yup.string(),
        address: Yup.string(),
    });
    
    const formOptions = {
        defaultValues: {
        owner_name: '',
        mobile: '',
        tel: '',
        email: '',
        address: ''
        },
        resolver: yupResolver(validationSchema)
    };
    
    const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    
    const formData = watch();
    
    useEffect(() => {
        dispatch(site())
        .then((res) => {
            reset(res)
        })
    }, []);
    
    
    const onSubmit = (data) => {
        dispatch(updateSite(data))
        .then((res) => {
        toast.success('사이트 정보를 수정했습니다..', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        })
        .catch(message => {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
        });
        });
    }

    const pwCheck = () => {
        // 나타나는 것만 적용하였고 데이터 일치 여부는 확인하지 않습니다.
        toast.error('패스워드가 일치하지 않습니다.', {
        osition: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

  return (
    <>
      <div className="container_adm">
          <div className="container_adm_inner">
              <div className="adm_title mb30">기본설정</div>
              <form id="form_setting" onSubmit={handleSubmit(onSubmit)}>
                  <div className="content_box mb30">
                      <h2 className="colblue">사이트 정보</h2>
                          <fieldset className="flex_wrap">
                              <div className="one-third">
                                  <label htmlFor="main_name">대표자 이름</label>
                                  <input type="text" id="main_name" className="input_default"  {...register('owner_name')}/>
                              </div>
                              <div className="one-third">
                                  <label htmlFor="main_mobile">휴대폰 번호</label>
                                  <input type="text" id="main_mobile" className="input_default"  {...register('mobile')}/>
                              </div>
                              <div className="one-third">
                                  <label htmlFor="main_phone">대표전화</label>
                                  <input type="text" id="owner_phone" className="input_default"  {...register('tel')}/>
                              </div>
                              <div className="one-third">
                                  <label htmlFor="main_email">대표 이메일</label>
                                  <input type="text" id="main_email" className="input_default"  {...register('email')}/>
                              </div>
                              <div className="two-third">
                                  <label htmlFor="main_address">주소</label>
                                  <input type="text" id="main_address" className="input_default"  {...register('address')} />
                              </div>
                          </fieldset>
                  </div>
                  {/* <div className="content_box mb30">
                      <h2 className="colblue">최고 관리자</h2>
                          <fieldset className="flex_wrap">
                              <div className="one-third">
                                  <label htmlFor="manager1_name">이름</label>
                                  <input type="text" id="manager1_name" className="input_default" value="홍길동" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager1_mobile">휴대폰 번호</label>
                                  <input type="text" id="manager1_mobile" className="input_default" value="01012345678" />
                              </div>
                              <div className="one-third"></div>
                              <div className="one-third">
                                  <label htmlFor="manager1_email">대표 이메일</label>
                                  <input type="text" id="manager1_email" className="input_default" value="dinplus@din365.com" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager1_pw">패스워드</label>
                                  <input type="text" id="manager1_pw" className="input_default" value="" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager1_pw_chk">패스워드 확인</label>
                                  <input type="text" id="manager1_pw_chk" className="input_default" value="" onChange={pwCheck} />
                              </div>
                              <ToastContainer
                                position="top-right"
                                autoClose={2500}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable
                                pauseOnHover={false}
                                theme="light"
                                />
                          </fieldset>
                  </div>
                  <div className="content_box mb30">
                      <h2 className="colblue">부 관리자 - 콘텐츠 등록자</h2>
                          <fieldset className="flex_wrap">
                              <div className="one-third">
                                  <label htmlFor="manager2_1_name">이름</label>
                                  <input type="text" id="manager2_1_name" className="input_default" value="홍길동" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager2_1_mobile">휴대폰 번호</label>
                                  <input type="text" id="manager2_1_mobile" className="input_default" value="01012345678" />
                              </div>
                              <div className="one-third"></div>
                              <div className="one-third">
                                  <label htmlFor="manager2_1_email">대표 이메일</label>
                                  <input type="text" id="manager2_1_email" className="input_default" value="dinplus@din365.com" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager2_1_pw">패스워드</label>
                                  <input type="text" id="manager2_1_pw" className="input_default" value="" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager2_1_pw_chk">패스워드 확인</label>
                                  <input type="text" id="manager2_1_pw_chk" className="input_default" value=""  onChange={pwCheck}/>
                              </div>
                          </fieldset>
                  </div>
                  <div className="content_box mb30">
                      <h2 className="colblue">부 관리자 - 콘텐츠 등록자</h2>
                          <fieldset className="flex_wrap">
                              <div className="one-third">
                                  <label htmlFor="manager2_2_name">이름</label>
                                  <input type="text" id="manager2_2_name" className="input_default" value="홍길동" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager2_2_mobile">휴대폰 번호</label>
                                  <input type="text" id="manager2_2_mobile" className="input_default" value="01012345678" />
                              </div>
                              <div className="one-third"></div>
                              <div className="one-third">
                                  <label htmlFor="manager2_2_email">대표 이메일</label>
                                  <input type="text" id="manager2_2_email" className="input_default" value="dinplus@din365.com" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager2_2_pw">패스워드</label>
                                  <input type="text" id="manager2_2_pw" className="input_default" value="" />
                              </div>
                              <div className="one-third">
                                  <label htmlFor="manager2_2_pw_chk">패스워드 확인</label>
                                  <input type="text" id="manager2_2_pw_chk" className="input_default" value=""  onChange={pwCheck}/>
                              </div>
                          </fieldset>
                  </div> */}
                  <div className="btns_wrap">
                      <button className="btn_green">저장</button>
                  </div>
              </form>
          </div>
      </div>
    </>
  );
}
