import axios from 'axios';
import config from '@config';

const instance = axios.create({
  baseURL: config.apiServerHost,
  headers: {
    'content-Type': 'application/json; charset=UTF-8'
  },
  withCredentials: true
});

export default instance;
