import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from '@helpers/history';

import Router from './routes'

import { setBasePath } from "@actions/common";
import { refreshToken } from '@actions/account';
import { setIsLoading } from './actions/common';

import PulseLoader from "react-spinners/BeatLoader";

function App() {
  const dispatch = useDispatch();

  history.navigate = useNavigate();
  history.location = useLocation();

  const { isLoading } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(setIsLoading(false));
  }, [history.location.pathname]);
  
  return (
    <>
      {isLoading && <div className='backdrop'>
        <PulseLoader
          color={'#00BF8E'}
          loading={true}
          size={15}
        />
      </div>}
      <Router/>
    </>  
  );
}

export default App;
