import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';

import { missions, activeMission, inactiveMission } from '@actions/mission';

const TeamList = () => {
    const dispatch = useDispatch();

  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [pageLimit, setPageLimit] = useState(20);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  
  const [searchText, setSearchText] = useState('');
  const [searchReset, setSearchReset] = useState(false);

  useEffect(() => {
    getList();
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if(searchReset){
      handleSearch()
    }
  }, [searchReset]);

  const getList = () => {
    if(searchReset){
      setSearchReset(false);
    }

    dispatch(missions({
      type: 'team',
      page: currentPage,
      limit: pageLimit,
      text: searchText
    }))
    .then((res) => {
      if(res.data.length === 0) {
        setIdList([])
        setTotalPageCount(0);
        setTotalItemCount(0);
        setCurrentItems([]);
        setCheckList([]);
      } else {
        setIdList(res.data.map(item=>item._id))
        setTotalPageCount((res?.metadata?.total || 1) < pageLimit ? 1 : Math.ceil((res?.metadata?.total || 1) / pageLimit));
        setTotalItemCount((res?.metadata?.total || 1));
        setCurrentItems(res.data);
        setCheckList([]);
      }
    })
  }

  const handleSearch = () => {
    setCurrentPage(1);
    getList();
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };


  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }


  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(value);
    setCurrentPage(1);
  }

  const onActiveMissions = () => {
    if(checkList.length === 0){
      toast.error('활성화 할 미션을 선택해주세요.');
      return;
    }

    dispatch(activeMission(checkList))
    .then((res) => {
      toast.success('활성화 되었습니다.');
      getList();
    })
  }

  const onInactiveMissions = () => {
    if(checkList.length === 0){
      toast.error('비활성화 할 미션을 선택해주세요.');
      return;
    }

    dispatch(inactiveMission(checkList))
    .then((res) => {
      toast.success('비활성화 되었습니다.');
      getList();
    })
  }

  return (
    <>
      {currentItems &&  <div className="container_adm">
            <div className="adm_title_wrap mb30">
                <div className="adm_title__left">
                    <div className="adm_title mr20">단체 미션 투어</div>
                </div>
                <div className="adm_title__right">
                    <div className="search_wrap">
                        <label htmlFor="검색어 입력" className="hide">검색어 입력</label>
                        <input type="text" id="search_text" placeholder="검색어를 입력해 주세요"/>
                        <button type="button" className="btn_search"><span className="hide">검색</span></button>
                    </div>
                    <select name="table_row" id="table_row" onChange={onChangePageLimit}>
                      <option value="10" selected={pageLimit==10}>10</option>
                      <option value="20" selected={pageLimit==20}>20</option>
                      <option value="30" selected={pageLimit==30}>30</option>
                    </select>
                </div>

            </div>
            <table className="table_default mb30">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>
                          <input type='checkbox' className="chk_default" onChange={onChangeAll} checked={checkList.length === idList.length} />
                        </th>
                        <th>제목</th>
                        <th>팀 / 인원</th>
                        <th>미션기간</th>
                        <th>미션시간</th>
                        <th>상태</th>
                        <th>편집</th>
                        <th>보기</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    currentItems.map((item, index)=>(
                      <tr key={item._id}>
                        <td>{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                        <td>
                          <input type='checkbox' className="chk_default" onChange={(e) => onChangeEach(e, item._id)} checked={checkList.includes(item._id)}/>
                        </td>
                        <td>{item.title}</td>
                        <td>{item.max_team}팀 / {item.max_team_persion}인</td>
                        <td><Moment format='YYYY.MM.DD'>{item.from_date}</Moment></td>
                        <td>3시간</td>
                        <td>
                          {item.active ? '노출' : '숨김'}
                        </td>
                        <td>
                          <Link to={`/team/${item._id}/edit`} className="colgreen"><strong>미션</strong></Link>
                          &nbsp;<small className="colgreen" style={{verticalAlign:'top'}}>|</small>&nbsp;
                          <Link to={`/team/${item._id}/quiz`} className="colgreen"><strong>퀴즈</strong></Link>
                        </td>
                        <td><a href="./ms_team_view.php" className="colblue"><strong>보기</strong></a></td>
                    </tr>
                    )
                )}
                </tbody>
            </table>
            <div className="paging mb30">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<span className='paging_end pl30'></span>}
                  onPageChange={handlePageClick}
                  forcePage={ currentPage - 1 }
                  pageRangeDisplayed={3}
                  pageCount={totalPageCount}
                  previousLabel={<span className='paging_first pr30'></span>}
                  renderOnZeroPageCount={null}
                  activeLinkClassName="paging_num paging_current"
                  pageLinkClassName="paging_num"
                  breakLinkClassName="paging_num"
                  previousLinkClassName="paging_num"
                  nextLinkClassName="paging_num"
                />
            </div>
            <div className="btns_wrap">
                <button className="btn_blue_line" onClick={()=>onInactiveMissions()}>선택 숨김</button>
                <button className="btn_blue_line" onClick={()=>onActiveMissions()}>선택 노출</button>
                <Link to="/team/add"><button className="btn_green">미션 추가</button></Link>
            </div>
        </div>
      }
    </>
  );
}

export default TeamList;