import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormCKEditor from '@/components/FormCKEditor';

import * as Yup from 'yup';

import randomBytes from 'randombytes';

import { mission, updateQuiz } from '@actions/mission';
import DaumPostcode from 'react-daum-postcode';
import Moment from 'react-moment';
import {Map, MapMarker} from 'react-kakao-maps-sdk';

const StampAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [missionData, setMissionData] = useState(null);

  const { basePath } = useSelector((state) => state.common);
  const [isLoadRewards, setIsLoadRewards] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2022/12/21"));
  const [endDate, setEndDate] = useState(new Date("2022/12/22"));
  const [description, setDescription] = useState('');
  const { kakao } = window

  const validationSchema = Yup.object().shape({
    stamp_image_num: Yup.number().required('필수 입력사항입니다.'),
    stamp_image_divide_num: Yup.number().required('필수 입력사항입니다.'),
    quiz: Yup.array()
  });

  const LocationTemplate = {
    type: 'location',
    question: '',
    address: '',
    mission: params.mission_id,
    isPostPop: false,
    location: {
      name: ''
    },
    score: 0,
    coordinate: {},
    description: '',
    removed: false
  }

  const formOptions = {
    defaultValues: {
      stamp_image_num: 1,
      stamp_image_divide_num: 4,
      quiz: [
        {
          _id: randomBytes(12).toString('hex'),
          ...LocationTemplate
        },
        {
          _id: randomBytes(12).toString('hex'),
          ...LocationTemplate
        },
        {
          _id: randomBytes(12).toString('hex'),
          ...LocationTemplate
        },
        {
          _id: randomBytes(12).toString('hex'),
          ...LocationTemplate
        },
      ]
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const { fields, move, append, remove, update, insert } = useFieldArray({ name: 'quiz', control });

  const formData = watch();

  console.log('formData', formData)

  useEffect(() => {
    dispatch(mission(params.mission_id))
      .then((res) => {
        if(res.quiz.length === 0){
          reset({
            stamp_image_num: 1,
            stamp_image_divide_num: 4,
            quiz: [
              {
                _id: randomBytes(12).toString('hex'),
                ...LocationTemplate
              },
              {
                _id: randomBytes(12).toString('hex'),
                ...LocationTemplate
              },
              {
                _id: randomBytes(12).toString('hex'),
                ...LocationTemplate
              },
              {
                _id: randomBytes(12).toString('hex'),
                ...LocationTemplate
              },
            ]
          });
        } else {
          reset(res);  
        }
        
        setMissionData(res);
      })
  }, []);

  const onSubmit = (data) => {
    console.log(data)
    dispatch(updateQuiz(params.mission_id, data))
    .then((res) => {
      console.log(res)
      // setIsMissionShow(true)
    //   setIsRewardsUp(true)
      // history.navigate(`/solo`, { replace: true })
      toast.success('퀴즈를 수정했습니다..', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const setQuiz = () => {
    if(formData.quiz.length !== formData.stamp_image_divide_num) {
      let quizLength = formData.quiz.length;
      let divideNum = formData.stamp_image_divide_num;

      if(quizLength < divideNum) {
        for(let i = quizLength; i < divideNum; i++) {
          append({
            _id: randomBytes(12).toString('hex'),
            ...LocationTemplate
          })
        }
      } else {
        for(let i = quizLength; i > divideNum; i--) {
          remove(i-1)
        }
      }
    }
  }

  const setCompleteAddress = (i, address) => {
    setValue(`quiz.${i}.address`, address)

    const geocoder = new kakao.maps.services.Geocoder();

    geocoder.addressSearch(address, (result, status) => {
      if (status == kakao.maps.services.Status.OK) {

        const coordinate = {
          lat: result[0].y,
          lng: result[0].x,
        }

        setValue(`quiz.${i}.coordinate`, coordinate)
        setValue(`quiz.${i}.isPostPop`, false)

        // const container = document.querySelector('.map-'+i);
        
        // const options = {
        //     center: new kakao.maps.LatLng(coordinate.lat, coordinate.lng),
        //     level: 3
        // };

        // const map = new kakao.maps.Map(container, options);

        // var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

        // var marker = new kakao.maps.Marker({
        //     map: map,
        //     position: coords
        // });

        // var infowindow = new kakao.maps.InfoWindow({
        //     content: '<div style="width:150px;text-align:center;padding:6px 0;">'+address+'</div>'
        // });
        // infowindow.open(map, marker);

        // map.setCenter(coords);
      } else {
        setValue(`quiz.${i}.address`, '')
      }
    });    
  }

  return (
    <>
      {missionData && <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">미션 추가</div>
                <div className="content_box mb30">
                    <div className="ms_step1_info">
                      <h2 className="mb30">{missionData.title}</h2>
                        <div>
                            <strong className="colblue">미션 기간</strong>
                            <span><Moment format='YYYY.MM.DD'>{missionData.from_date}</Moment> ~ <Moment format='YYYY.MM.DD'>{missionData.to_date}</Moment></span>
                            <strong className="colblue">미션 시간</strong>
                            <span>{missionData.limit_time}시간</span>
                            <strong className="colblue">최대 인원</strong>
                            <span>{missionData.max_person}명</span>
                        </div>
                    </div>
                </div>
                <form id="ms_add" onSubmit={handleSubmit(onSubmit)}>
                    <div className="content_box mb30">
                        <h2 className="colblue">캐릭터 선택</h2>
                        <fieldset className="flex_wrap">
                            <div className="one-third">
                              <label htmlFor="stamp_num">분할</label>
                              <select name="stamp_num" id="stamp_num" className="select_default" {...register('stamp_image_divide_num')}>
                                  <option value="4">4</option>
                                  <option value="9">9</option>
                              </select>
                            </div>
                            <div className="stamp_character_wrap">
                                <label htmlFor="stamp_img">선택(택1)</label>
                                <div className="stamp_img_wrap">
                                    <label htmlFor="stamp_img1">
                                        <input type="radio" id="stamp_img1" name="stamp_img" {...register('stamp_image_num')} value={1} checked={formData.stamp_image_num == 1}/>
                                        <img src={require("@assets/img/img_stamp1.png")} alt="스탬프투어 캐릭터1" />
                                    </label>
                                    <label htmlFor="stamp_img2">
                                        <input type="radio" id="stamp_img2" name="stamp_img" {...register('stamp_image_num')} value={2} checked={formData.stamp_image_num == 2} />
                                        <img src={require("@assets/img/img_stamp2.png")} alt="스탬프투어 캐릭터2" />
                                    </label>
                                    <label htmlFor="stamp_img3">
                                        <input type="radio" id="stamp_img3" name="stamp_img" {...register('stamp_image_num')} value={3} checked={formData.stamp_image_num == 3} />
                                        <img src={require("@assets/img/img_stamp3.png")} alt="스탬프투어 캐릭터3" />
                                    </label>
                                    <label htmlFor="stamp_img4">
                                        <input type="radio" id="stamp_img4" name="stamp_img" {...register('stamp_image_num')} value={4} checked={formData.stamp_image_num == 4} />
                                        <img src={require("@assets/img/img_stamp4.png")} alt="스탬프투어 캐릭터4" />
                                    </label>
                                    <label htmlFor="stamp_img5">
                                        <input type="radio" id="stamp_img5" name="stamp_img" {...register('stamp_image_num')} value={5} checked={formData.stamp_image_num == 5} />
                                        <img src={require("@assets/img/img_stamp5.png")} alt="스탬프투어 캐릭터5" />
                                    </label>
                                    <label htmlFor="stamp_img6">
                                        <input type="radio" id="stamp_img6" name="stamp_img" {...register('stamp_image_num')} value={6} checked={formData.stamp_image_num == 6} />
                                        <img src={require("@assets/img/img_stamp6.png")} alt="스탬프투어 캐릭터6" />
                                    </label>
                                    <label htmlFor="stamp_img7">
                                        <input type="radio" id="stamp_img7" name="stamp_img" {...register('stamp_image_num')} value={7} checked={formData.stamp_image_num == 7} />
                                        <img src={require("@assets/img/img_stamp7.png")} alt="스탬프투어 캐릭터7" />
                                    </label>
                                    <label htmlFor="stamp_img8">
                                        <input type="radio" id="stamp_img8" name="stamp_img" {...register('stamp_image_num')} value={8} checked={formData.stamp_image_num == 8} />
                                        <img src={require("@assets/img/img_stamp8.png")} alt="스탬프투어 캐릭터8" />
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="btns_wrap mb30">
                        <button type='button' className="btn_green" onClick={()=>setQuiz()}>선택 완료</button>
                    </div>
                    {formData.quiz && formData.quiz.map((item, i)=><div className="content_box mission_box mb30" key={item.id}>
                        <div className="mission_box__left">
                            <h2 className="colblue mb30">장소 {i+1}</h2>
                        </div>
                        <div className="mission_box__right">
                            <div className="stamp_line1">
                                <label htmlFor="stamp_location" className="right_label">장소명</label>
                                <input type="text" placeholder="장소명을 입력해 주세요" id="stamp_location" className="input_default one-third" {...register(`quiz.${i}.location.name`)}/>
                            </div>
                            <div className="stamp_line2">
                                <label htmlFor="stamp_location" className="right_label">주소</label>
                                <div className="input_address_wrap mb30" style={{width:'100%'}}>
                                <div className="input_postcode">
                                  <input type="text" id="acc_add2" className="input_default" {...register(`quiz.${i}.address`)} readOnly/>
                                  <button type='button' onClick={()=>setValue(`quiz.${i}.isPostPop`, true)}>주소등록</button>
                                  {item.isPostPop && 
                                    <DaumPostcode 
                                        onComplete={e=>setCompleteAddress(i, e.address)}
                                        autoClose={false}
                                        defaultQuery={item?.address}
                                        style={{
                                          position:'absolute',
                                          display:'block',
                                          top:'0',
                                          left:'0',
                                          width:'100%',
                                          height:'50vh',
                                          zIndex: 9,
                                        }}
                                  />}
                                </div>
                                {item.coordinate.lat && item.coordinate.lng && <Map
                                  center={{
                                    lat: item.coordinate.lat,
                                    lng: item.coordinate.lng,
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "350px",
                                  }}
                                  level={3}
                                >
                                  <MapMarker
                                    position={{
                                      lat: item.coordinate?.lat,
                                      lng: item.coordinate?.lng,
                                    }}
                                  />
                                </Map>}
                              </div>
                            </div>
                            <div className="stamp_line3">
                                <label htmlFor="ms_type1_q" className="right_label">제목</label>
                                <input type="text" className="input_default" id="ms_type1_q" {...register(`quiz.${i}.title`)}  placeholder='미션명을 입력해 주세요.'/>
                            </div>
                            <div className="stamp_line4">
                                <label htmlFor="" className="right_label">간단 설명</label>
                                <FormCKEditor
                                  data={item.description}
                                  handleChange={(value) => {
                                    setValue(`quiz.${i}.description`, value);
                                  }}
                                  placeholder='미션설명을 입력해 주세요.'
                                />
                            </div>
                        </div>
                    </div>)}
                    <div className="btns_wrap">
                        <button className="btn_green_line" onClick={()=>{history.navigate('/stamp', {replace: true}); }}>취소</button>
                        <button type='submit' className="btn_green">등록완료</button>
                    </div>
                </form>
            </div>
        </div>
     }
        
    </>
  );
}

export default StampAdd;
