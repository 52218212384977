import api from '@/services/api';
import queryString from 'query-string';

class SpotService {
  createSpot(params) {
    return api.post('/spots', params)
  }

  spot(spot_id) {
    return api.get(`/spots/${spot_id}`)
  }

  spots(params) {
    return api.get(`/spots?${queryString.stringify(params)}`)
  }

  updateSpot(spot_id, params) {
    return api.put(`/spots/${spot_id}`, params)
  }

  deleteSpot(spot_id) {
    return api.delete(`/spots/${spot_id}`)
  }

  activeSpot(params) {
    return api
      .post(`/spots/active`,params)
  }

  inactiveSpot(params) {
    return api
      .post(`/spots/inactive`,params)
  }

  deleteAdditionalImage(spot_id, idx) {
    return api.delete(`/spots/${spot_id}/additional-image/${idx}`)
  }
}

export default new SpotService();
