
import siteService from '@/services/site.service';

export const updateSite = (site_id, params) => (dispatch) => {
  return siteService.updateSite(site_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateTerms = (site_id, params) => (dispatch) => {
  return siteService.updateTerms(site_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const site = () => (dispatch) => {
  return siteService.site()
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};