import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { htmlFormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';
import FormCKEditor from '@/components/FormCKEditor';
import { FormattedMessage } from 'react-intl';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import randomBytes from 'randombytes';

import { createNotice } from '@actions/board';

const NoticeAdd = () => {
  const dispatch = useDispatch();
  const [isNoticeUp, setIsNoticeUp] = useState(false);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    type: Yup.string(),
    title: Yup.string().required('제목을 입력해 주세요'),
    body: Yup.string().required('내용을 입력해 주세요'),
    link: Yup.string().url('올바른 웹사이트 주소를 입력해 주세요'),
    cover_image: Yup.mixed(),
    attachment1: Yup.mixed(),
    attachment2: Yup.mixed(),
    attachment3: Yup.mixed(),
    attachment4: Yup.mixed(),
    attachment5: Yup.mixed(),
    attachment6: Yup.mixed(),
    attachment7: Yup.mixed(),
    attachment8: Yup.mixed(),
    attachment9: Yup.mixed(),
    attachment10: Yup.mixed(),
    is_notice: Yup.boolean()
  });

  const formOptions = {
    defaultValues: {
      _id: randomBytes(12).toString('hex'),
      title: '',
      body: '',
      link: '',
      cover_image: '',
      attachment1: '',
      attachment2: '',
      attachment3: '',
      attachment4: '',
      attachment5: '',
      attachment6: '',
      attachment7: '',
      attachment8: '',
      attachment9: '',
      attachment10: '',
      is_notice: false,
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  console.log('formData', formData)

  console.log(errors)

  const onSubmit = (data) => {
     const formData = new FormData();
    formData.append('type', 'notice');
    formData.append("title", data.title);

    if(data.attachment1[0]){
      formData.append("attachment1", data.attachment1[0]);
    }

    if(data.attachment2[0]){
      formData.append("attachment2", data.attachment2[0]);
    }

    if(data.attachment3[0]){
      formData.append("attachment3", data.attachment3[0]);
    }

    if(data.attachment4[0]){
      formData.append("attachment4", data.attachment4[0]);
    }

    if(data.attachment5[0]){
      formData.append("attachment5", data.attachment5[0]);
    }

    formData.append("body", data.body);
    formData.append("link", data.link);
    formData.append("is_notice", data.is_notice);
    
    dispatch(createNotice(formData))
    .then((res) => {
        history.navigate(`/notice`, {replace: true})
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
        <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">공지사항 추가</div>
                <form id="htmlForm_setting" onSubmit={handleSubmit(onSubmit)}> 
                    <div className="content_box mb30">
                        <h2 className="colblue">기본 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="two-third">
                                <label htmlFor="spots_name" className="required">제목</label>
                                <div className='input_wrap'>
                                  <input type="text" id="spots_name" className="input_default" {...register("title")}/>
                                  {errors && errors?.title?.message && <p className="input_warn">
                                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.title?.message}
                                  </p>}
                                </div>
                            </div>
                            <div className="three-third">
                                <label htmlFor="ms_date_from" className="required">내용</label>
                                <div className='input_wrap'>
                                  <FormCKEditor
                                      data={formData.body}
                                      handleChange={(value) => {
                                          setValue('body', value);
                                      }}
                                  />
                                  {errors && errors?.body?.message && <p className="input_warn">
                                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.body?.message}
                                  </p>}
                                </div>
                                
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">첨부파일1</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                      <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment1")}/>
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">첨부파일2</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                      <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment2")}/>
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">첨부파일3</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                      <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment3")}/>
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">첨부파일4</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                      <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment4")}/>
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_name">첨부파일5</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                      <input type="file" name="ms_thumb" id="ms_thumb" {...register("attachment5")}/>
                                    </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            <div className="two-third">
                                <label htmlFor="">링크</label>
                                <div className='input_wrap'>
                                  <input type="text" className="input_default" placeholder="URL을 입력해 주세요" {...register("link")}/>
                                  {errors && errors?.link?.message && <p className="input_warn">
                                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.link?.message}
                                  </p>}
                                </div>
                            </div>
                            <div className="one-third">
                                <label htmlFor="notice_if">공지</label>
                                <input type="checkbox" name="notice_if" id="notice_if" className="chk_default" value={true} {...register("is_notice")}/>
                            </div>
                        </fieldset>
                    </div>
                    <div className="btns_wrap">
                        <button className="btn_green_line" onClick={()=>{history.navigate('/notice'); }}>취소</button>
                        <button type="submit" className="btn_green">등록완료</button>
                    </div>
                </form>
            </div>
        </div>
        {/* <div id="modalNoticeUp" className="modal" style={{display: isNoticeUp ? 'block' : 'none'}}>
            <div className='modal_wrap'>
                <div className='modal_content'>
                    <form>
                        <div className="modal_body">
                            <p style={{paddingTop:'50px'}}>등록이 완료되었습니다.</p>
                        </div>
                        <div className="modal_footer">
                            <button type="submit" className="btn_green" onClick={()=>{history.navigate('/notice'); }}>확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div> */}
    </>
  );
}

export default NoticeAdd;
