import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import rewardsImg from '@assets/img/img_rw1.png';
import FormCKEditor from '@/components/FormCKEditor';
import _ from 'lodash';
import moment from 'moment'

import randomBytes from 'randombytes';

import { mission, updateMission, deleteAdditionalImage } from '@actions/mission';
import { giveaways } from '@actions/giveaway';
import config from '@/config';
import Swal from 'sweetalert2'

const SoloEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [missionData, setMissionData] = useState(null);
  const [isMissionCancel, setIsMissionCancel] = useState(false);
  const [isFileUploadSpan, setIsFileUploadSpan] = useState(false);
  const [giveawayList, setGiveawayList] = useState(null);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    type: Yup.string(),
    title: Yup.string(),
    from_date: Yup.string(),
    to_date: Yup.string(),
    is_unlimit_date: Yup.boolean(),
    limit_time: Yup.number(),
    max_person: Yup.number().nullable(true),
    description: Yup.string(),
    giveaway: Yup.array(),
    giveaway_description: Yup.string()
  });

  const formOptions = {
    defaultValues: {
      _id: randomBytes(12).toString('hex'),
      title: '',
      description: ''
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  console.log('formData', formData)

  useEffect(() => {
    dispatch(giveaways({
        page: 1,
        limit: 100000
      }))
      .then((res) => {
        setGiveawayList(res.docs);
      })
  }, []);

  useEffect(() => {
    getMissionData()
  }, []);

  const getMissionData = () => {
    dispatch(mission(params.mission_id))
    .then((res) => {
      reset(res);

      setValue('from_date', moment(res.from_date).format("YYYY-MM-DD"))
      setValue('from_date_time', res.from_date.length > 10 ? moment(res.from_date).format("HH:mm") : '00:00:00')

      setValue('to_date', moment(res.to_date).format("YYYY-MM-DD"))
      setValue('to_date_time', res.to_date.length > 10 ? moment(res.to_date).format("HH:mm") : '23:59:00')

      setMissionData(res);
    })
  }

  const onSubmit = (data) => {
    if(!data.title){
      toast.error('제목을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    } else if (!data.from_date) {
      toast.error('미션 시작일을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    } else if (!data.to_date) {
      toast.error('미션 종료일을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    } else if (!data.limit_time) {
      toast.error('미션 시간을 입력해 주세요.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
      return;
    }

    const formData = new FormData();
    formData.append('type', 'solo');
    formData.append("title", data.title);

    formData.append("from_date", `${moment(data.from_date).format("YYYY-MM-DD")} ${data.from_date_time ? data.from_date_time : '00:00:00'}`);
    formData.append("to_date", `${moment(data.to_date).format("YYYY-MM-DD")} ${data.to_date_time ? data.to_date_time : '23:59:59'}`);

    if(data.is_unlimit_date){
      formData.append("is_unlimit_date", data.is_unlimit_date);
    }

    if(data.limit_time){
      formData.append("limit_time", data.limit_time);
    }

    if(data.max_person){
      formData.append("max_person", data.max_person);
    }  

    if(!data.max_person){
      formData.append("is_max_person", true);
    }  

    if(data.intro_image_src[0]){
      formData.append("intro_image", data.intro_image_src[0]);
    }

    if(data.additional_image1_src[0]){
      formData.append("additional_image1", data.additional_image1_src[0]);
    }

    if(data.additional_image2_src[0]){
        formData.append("additional_image2", data.additional_image2_src[0]);
    }

    if(data.additional_image4_src[0]){
        formData.append("additional_image4", data.additional_image4_src[0]);
    }

    if(data.additional_image5_src[0]){
        formData.append("additional_image5", data.additional_image5_src[0]);
    }

    if(data.additional_image6_src[0]){
      formData.append("additional_image6", data.additional_image6_src[0]);
    }

    if(data.additional_image7_src[0]){
      formData.append("additional_image7", data.additional_image7_src[0]);
    }

    if(data.additional_image8_src[0]){
      formData.append("additional_image8", data.additional_image8_src[0]);
    }

    if(data.additional_image9_src[0]){
      formData.append("additional_image9", data.additional_image9_src[0]);
    }

    if(data.additional_image10_src[0]){
      formData.append("additional_image10", data.additional_image10_src[0]);
    }

    formData.append("description", data.description);
    formData.append("giveaway", data.giveaway);
    formData.append("giveaway_description", data.giveaway_description);

    dispatch(updateMission(params.mission_id, formData))
    .then((res) => {
      getMissionData()
      toast.success('미션정보를 수정했습니다..', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  //delete additional image
  const onDeleteAdditionalImage = (idx) => {
    Swal.fire({
      text: `${idx}번 추가이미지를 삭제 하시겠습니까?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: '아니요',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAdditionalImage(params.mission_id, idx))
        .then(res=>{
          getMissionData()
          toast.success('추가 이미지를 삭제했습니다.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        })
      }
    })
  }

  return (
    <>
        {missionData && <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title_sm mb30">미션 수정</div>
                <form id="form_missionAdd" onSubmit={handleSubmit(onSubmit)}> 
                    <div className="content_box mb30">
                        <h2 className="colblue">기본 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="two-third">
                                <label htmlFor="ms_name" className="required">제목</label>
                                <input type="text" id="ms_name" {...register('title')} className="input_default" />
                            </div>
                            <div className="two-third">
                                <label htmlFor="ms_date_from" className="required">미션 기간</label>
                                <div className="ms_datepicker">
                                    <label htmlFor="ms_date_from">
                                      <DatePicker
                                          selected={new Date(formData.from_date)}
                                          onChange={(date) => setValue('from_date', date)}
                                          dateFormat="yyyy-MM-dd"
                                          selectsStart
                                          startDate={new Date(formData.from_date)}
                                          endDate={new Date(formData.to_date)}
                                      />
                                      {/* input time */}
                                      <input type='time' {...register('from_date_time')} className="input_default" />
                                    </label>
                                    <span>~</span>
                                    <label htmlFor="ms_date_to" className="mr10">
                                    <DatePicker
                                        selected={new Date(formData.to_date)}
                                        onChange={(date) => setValue('to_date', date)}
                                        dateFormat="yyyy-MM-dd"
                                        selectsEnd
                                        startDate={new Date(formData.from_date)}
                                        endDate={new Date(formData.to_date)}
                                        minDate={new Date(formData.from_date)}
                                    />
                                    <input type='time' {...register('to_date_time')} className="input_default" />
                                    </label>
                                    <input type="checkbox" name="ms_date_nolimit" id="ms_date_nolimit" {...register("is_unlimit_date")} className="chk_default" value={true} />
                                    <label htmlFor="ms_date_nolimit">기간제한 없음</label>
                                </div>
                            </div>
                            <div className="one-third">
                                <label htmlFor="ms_duration" className="required">미션 시간</label>
                                <input type="number" id="ms_duration" className="input_default" {...register("limit_time")} min={1} placeholder="시간 기준 (ex. 5)" />
                            </div>
                            <div className="one-third">
                                <label htmlFor="ms_max">최대 인원</label>
                                <input type="number" id="ms_max" className="input_default" {...register("max_person")} placeholder="미입력 시 N명으로 설정됩니다" />
                            </div>
                        </fieldset>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">미션 상세 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="two-third">
                                <label htmlFor="ms_name" className="required">대표 이미지</label>
                                <div className="file_upload_wrap">
                                  <label htmlFor="ms_thumb">
                                    <input type="file" {...register("intro_image_src")}  accept="image/png, image/jpg" />
                                  </label>
                                </div>
                                {/* <button className="btn_add_sm"><span className="hide">이미지 추가</span></button> */}
                            </div>
                            {formData?.intro_image?.server_filename && <div className="two-third">
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.intro_image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지1</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image1_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                            </div>
                            {formData?.additional_image1?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(1)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image1?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지2</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image2_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image2?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(2)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image2?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지3</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image3_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image3?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(3)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image3?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지4</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image4_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image4?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(4)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image4?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지5</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image5_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image5?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(5)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image5?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지6</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image6_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image6?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(6)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image6?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지7</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image7_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image7?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(7)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image7?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지8</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image8_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image8?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(8)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image8?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지9</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image9_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image9?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(9)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image9?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="two-third">
                                <label htmlFor="ms_name">추가 이미지10</label>
                                <div className="file_upload_wrap">
                                    <label htmlFor="ms_thumb">
                                        <input type="file" {...register("additional_image10_src")} accept="image/png, image/jpg" />
                                    </label>
                                </div>
                                {/* <button className="btn_remove"><span className="hide">이미지 삭제</span></button> */}
                            </div>
                            {formData?.additional_image10?.server_filename && <div className="two-third">
                              <button type='button' className="btn_remove" onClick={()=>onDeleteAdditionalImage(10)}><span className="hide">이미지 삭제</span></button>
                              <img src={`${config.apiServerHost}/missions/attachment/${formData?.additional_image10?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/500'} />
                            </div>}
                            <div className="three-third">
                                <label htmlFor="ms_date_from" className="required">미션 설명</label>
                                <FormCKEditor
                                    data={formData.description}
                                    handleChange={(value) => {
                                        setValue('description', value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">경품 상세 정보</h2>
                        <fieldset className="flex_wrap flex_wrap2">
                            <div className="three-third">
                              <div className="rewards_list_wrap mb30">
                                  {
                                      giveawayList && giveawayList.map((item, ri)=><div className="reward_item">
                                      <label htmlFor={`reward_item_${item.id}`}>
                                          <input type="checkbox" name={`reward_item_${item._id}`} id={`reward_item_${item.id}`} className="chk_default" {...register("giveaway")} value={item.id}/>
                                          <img src={`${config.apiServerHost}/giveaways/attachment/${item?.image?.server_filename}`} alt="" />
                                      </label>
                                      <p>{item.title}</p>
                                  </div>)
                                  }
                              </div>
                            </div>
                            <div className="three-third">
                                <label htmlFor="ms_date_from">경품 안내 설명</label>
                                <FormCKEditor
                                    data={formData.giveaway_description}
                                    handleChange={(value) => {
                                        setValue('giveaway_description', value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                    <div className="btns_wrap">
                        <button type="button" className="btn_green_line" onClick={()=>setIsMissionCancel(true)}>취소</button>
                        <button type="submit" className="btn_green">수정</button>
                    </div>
                </form>
            </div>
        </div>}
    </>
  );
}

export default SoloEdit;
