export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_IS_LOADING = 'SET_IS_LOADING';