import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import { login } from '@actions/account';

import * as Yup from 'yup';

const LoginHome = () => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required('아이디를 입력해 주세요.').email('이메일을 입력해 주세요.'),
    password: Yup.string().required('비밀번호를 입력해 주세요.')
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const onSubmit = (params) => {
    dispatch(login(params.user_id, params.password))
    .then((res) => {
      history.navigate(`/`, { replace: true })
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
      <div className="container_full">
        <div className="cms_index_wrap">
            <div className="cms_index__left">
                <h1><img src={require('@assets/img/logo.svg').default} alt="로고" /></h1>
                <p>미션게임으로 즐기는 여행, 미션프렌즈</p>
            </div>
            <div className="cms_index__right">
                <div className="inner">
                    <form id="login_email" onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="mb30">LOGIN</h3>
                        <fieldset>
                            <label htmlFor="cms_id">
                                <input type="text" id="cms_id" className="input_default" placeholder="ID" {...register("user_id")} />
                            </label>
                            {errors && errors?.user_id?.message && <p className="input_warn">
                              <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.user_id?.message}
                            </p>}
                            <label htmlFor="cms_pw">
                                <input type="password" id="cms_pw" className="input_default" placeholder="Password" {...register("password")} />
                            </label>
                            {errors && errors?.password?.message && <p className="input_warn">
                              <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.password?.message}
                            </p>}
                        </fieldset>
                        <button type='submit' disabled={isSubmitting}>Login</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default LoginHome;
