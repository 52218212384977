
import missionService from '@/services/mission.service';

export const createMission = (params) => (dispatch) => {
  return missionService.createMission(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateMission = (mission_id, params) => (dispatch) => {
  return missionService.updateMission(mission_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateQuiz = (mission_id, params) => (dispatch) => {
  return missionService.updateQuiz(mission_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteMission = (mission_id) => (dispatch) => {
  return missionService.deleteMission(mission_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const missions = (params) => (dispatch) => {
  return missionService.missions(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const mission = (mission_id) => (dispatch) => {
  return missionService.mission(mission_id)
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const activeMission = (ids=[]) => (dispatch) => {
  return missionService.activeMission({ids: ids})
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const inactiveMission = (ids=[]) => (dispatch) => {
  return missionService.inactiveMission({ids: ids})
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteAdditionalImage = (mission_id, idx) => (dispatch) => {
  return missionService.deleteAdditionalImage(mission_id, idx)
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};