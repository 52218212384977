import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import * as Yup from 'yup';
import FormCKEditor from '@/components/FormCKEditor';

import { site, updateTerms } from '@actions/site';

const TermHome = () => {
  const dispatch = useDispatch();
  
  const validationSchema = Yup.object().shape({
    young_terms: Yup.string(),
    use_terms: Yup.string(),
    privacy_terms: Yup.string()
  });

  const formOptions = {
    defaultValues: {
      young_terms: '',
      use_terms: '',
      privacy_terms: '',
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  useEffect(() => {
    dispatch(site())
      .then((res) => {
        reset(res)
      })
  }, []);


  const onSubmit = (data) => {
    dispatch(updateTerms(data))
    .then((res) => {
      toast.success('약관을 수정했습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
        <div className="container_adm">
            <div className="container_adm_inner">
                <div className="adm_title mb30">약관 관리</div>
                <form id="form_term" onSubmit={handleSubmit(onSubmit)}> 
                    <div className="content_box mb30">
                        <h2 className="colblue">만 14세 이상 입니다</h2>
                        <FormCKEditor
                            data={formData.young_terms}
                            handleChange={(value) => {
                                setValue('young_terms', value);
                            }}
                            />
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">서비스 이용약관</h2>
                        <FormCKEditor
                            data={formData.use_terms}
                            handleChange={(value) => {
                              setValue('use_terms', value);
                            }}
                            />
                    </div>
                    <div className="content_box mb30">
                        <h2 className="colblue">개인정보 처리방침</h2>
                        <FormCKEditor
                            data={formData.privacy_terms}
                            handleChange={(value) => {
                              setValue('privacy_terms', value);
                            }}
                            />
                    </div>
                    <div className="btns_wrap">
                        <button type="submit" className="btn_green">저장</button>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
}

export default TermHome;
