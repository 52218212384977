import boardService from '@/services/board.service';

export const createEvent = (params) => (dispatch) => {
  return boardService.createEvent(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateEvent = (event_id, params) => (dispatch) => {
  return boardService.updateEvent(event_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteEvent = (event_id) => (dispatch) => {
  return boardService.deleteEvent(event_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const createNotice = (params) => (dispatch) => {
  return boardService.createNotice(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateNotice = (notice_id, params) => (dispatch) => {
  return boardService.updateNotice(notice_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteNotice = (notice_id) => (dispatch) => {
  return boardService.deleteNotice(notice_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};


export const createQuestion = (params) => (dispatch) => {
  return boardService.createQuestion(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateQuestion = (question_id, params) => (dispatch) => {
  return boardService.updateQuestion(question_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteQuestion = (question_id) => (dispatch) => {
  return boardService.deleteQuestion(question_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const notices = (params) => (dispatch) => {
  return boardService.notices(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const notice = (notice_id) => (dispatch) => {
  return boardService.notice(notice_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const events = (params) => (dispatch) => {
  return boardService.events(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const event = (event_id) => (dispatch) => {
  return boardService.event(event_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const questions = (params) => (dispatch) => {
  return boardService.questions(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const question = (question_id) => (dispatch) => {
  return boardService.question(question_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const createAnswer = (question_id, params) => (dispatch) => {
  return boardService.createAnswer(question_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateAnswer = (question_id, answer_id, params) => (dispatch) => {
  return boardService.updateAnswer(question_id, answer_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteAnswer = (question_id, answer_id) => (dispatch) => {
  return boardService.deleteAnswer(question_id, answer_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const activeBoard = (ids=[]) => (dispatch) => {
  return boardService.activeBoard({ids: ids})
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const inactiveBoard = (ids=[]) => (dispatch) => {
  return boardService.inactiveBoard({ids: ids})
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteBoard = (ids=[]) => (dispatch) => {
  return boardService.deleteBoard({ids: ids})
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteAttachmentFile = (board_id, idx) => (dispatch) => {
  return boardService.deleteAttachmentFile(board_id, idx)
    .then((response)=>{
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};