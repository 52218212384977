import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'

import { events, activeBoard,  inactiveBoard, deleteBoard } from '@actions/board';

const EventList = () => {
    const dispatch = useDispatch();

    const [checkList, setCheckList] = useState([]);
    const [idList, setIdList] = useState([]);
    const [pageLimit, setPageLimit] = useState(20);
    const [currentItems, setCurrentItems] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
  
    const [searchText, setSearchText] = useState('');
    const [searchReset, setSearchReset] = useState(false);
  
    useEffect(() => {
        getList();
    }, [currentPage, pageLimit]);
  
    useEffect(() => {
        if(searchReset){
        handleSearch()
        }
    }, [searchReset]);
  
    const getList = () => {
      if(searchReset){
      setSearchReset(false);
      }
  
      dispatch(events({
        page: currentPage,
        limit: pageLimit,
        text: searchText
      }))
      .then((res) => {
        setIdList(res.data.map(item=>item._id))
        setTotalPageCount((res?.metadata?.total || 1) < pageLimit ? 1 : Math.ceil((res?.metadata?.total || 1) / pageLimit));
        setTotalItemCount((res?.metadata?.total || 1));
        setCurrentItems(res.data);
        setCheckList([]);
      })
    }
  
    const handleSearch = () => {
        setCurrentPage(1);
        getList();
    }
  
    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };
  
  
    const onChangeAll = (e) => {
        setCheckList(e.target.checked ? idList : [])
    }
  
    const onChangeEach = (e, id) => {
        if (e.target.checked) {
        setCheckList([...checkList, id]);
        } else {
        setCheckList(checkList.filter((checkedId) => checkedId !== id));
        }
    }
  
    const onChangePageLimit = (e) => {
        const value = e.target.value || 20;
        setPageLimit(value);
        setCurrentPage(1);
    }


  //select all delete
  const onDeleteBoards = () => {
    if(checkList.length === 0){
      toast.error('삭제 할 게시물을 선택해주세요.');
      return;
    }
    Swal.fire({
      text: '삭제 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: '아니요',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBoard(checkList))
        .then((res) => {
          toast.success('삭제 되었습니다.');
          getList();
        })
      }
    })
  }

  const onActiveBoards = () => {
    if(checkList.length === 0){
      toast.error('활성화 할 게시물을 선택해주세요.');
      return;
    }

    dispatch(activeBoard(checkList))
    .then((res) => {
      toast.success('활성화 되었습니다.');
      getList();
    })
  }

  const onInactiveBoards = () => {
    if(checkList.length === 0){
      toast.error('비활성화 할 게시물을 선택해주세요.');
      return;
    }

    dispatch(inactiveBoard(checkList))
    .then((res) => {
      toast.success('비활성화 되었습니다.');
      getList();
    })
  }

  return (
    <>
      {currentItems && <div className="container_adm">
            <div className="adm_title_wrap mb30">
                <div className="adm_title__left">
                    <div className="adm_title mr20">이벤트 관리</div>
                </div>
                <div className="adm_title__right">
                    <div className="search_wrap">
                        <label htmlFor="검색어 입력" className="hide">검색어 입력</label>
                        <input type="text" id="search_text" placeholder="검색어를 입력해 주세요"/>
                        <button type="button" className="btn_search"><span className="hide">검색</span></button>
                    </div>
                    <select name="table_row" id="table_row" onChange={onChangePageLimit}>
                      <option value="10" selected={pageLimit === 10}>10</option>
                      <option value="20" selected={pageLimit === 20}>20</option>
                      <option value="30" selected={pageLimit === 30}>30</option>
                    </select>
                </div>

            </div>
            <table className="table_default mb30">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>
                          <input type='checkbox' className="chk_default" onChange={onChangeAll} checked={checkList.length === idList.length} />
                        </th>                        
                        <th style={{width:'50%'}} className="left">제목</th>
                        <th>등록일</th>
                        <th>상태</th>
                        <th>편집</th>
                        <th>보기</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index)=><tr>
                        <td>{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                        <td>
                          <input type='checkbox' className="chk_default" onChange={(e) => onChangeEach(e, item._id)} checked={checkList.includes(item._id)}/>
                        </td>
                        <td className="left">{item.title}</td>
                        <td><Moment format='YYYY.MM.DD'>{item.created}</Moment></td>
                        <td>
                          {item.active ? '노출' : '숨김'}
                        </td>
                        <td><Link to={`/event/${item._id}/edit`} className="colgreen"><strong>수정</strong></Link></td>
                        <td><Link to={`/`} className="colblue"><strong>보기</strong></Link></td>
                    </tr>)}
                    {
                      currentItems.length === 0 && <tr>
                        <td colSpan="7">검색 결과가 없습니다.</td>
                      </tr>
                    }
                </tbody>
            </table>
            <div className="paging mb30">
                {/* <button type="button" className="paging_arrow paging_first"><span className="hide">첫 페이지로</span></button>
                <button type="button" className="paging_num active">1</button>
                <button type="button" className="paging_num">2</button>
                <button type="button" className="paging_arrow paging_end"><span className="hide">마지막 페이지로</span></button> */}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<span className='paging_end pl30'></span>}
                  onPageChange={handlePageClick}
                  forcePage={ currentPage - 1 }
                  pageRangeDisplayed={3}
                  pageCount={totalPageCount}
                  previousLabel={<span className='paging_first pr30'></span>}
                  renderOnZeroPageCount={null}
                  activeLinkClassName="paging_num paging_current"
                  pageLinkClassName="paging_num"
                  breakLinkClassName="paging_num"
                  previousLinkClassName="paging_num"
                  nextLinkClassName="paging_num"
                />
            </div>
            <div className="btns_wrap">
              <button className="btn_blue_line" onClick={()=>onDeleteBoards()}>선택 삭제</button>
              <button className="btn_blue_line" onClick={()=>onInactiveBoards()}>선택 숨김</button>
              <button className="btn_blue_line" onClick={()=>onActiveBoards()}>선택 노출</button>
              <Link to="/event/add"><button className="btn_green">추가</button></Link>
            </div>
        </div>
        }
    </>
  );
}

export default EventList;
